import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de_assignments from "./locales/de/assignments.json";
import de_auth from "./locales/de/auth.json";
import de_common from "./locales/de/common.json";
import en_assignments from "./locales/en/assignments.json";
import en_auth from "./locales/en/auth.json";
import en_common from "./locales/en/common.json";

export const defaultNS = "common";

export const resources = {
  en: {
    common: en_common,
    auth: en_auth,
    assignments: en_assignments,
  },
  de: {
    common: de_common,
    auth: de_auth,
    assignments: de_assignments,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    lng: "de",
    ns: ["common", "auth", "assignments"],
    defaultNS,
    resources,
  });

export default i18n;
