type Props = {
  class: string;
  content: string;
  icon: React.ReactElement;
};

export const Tooltip = (props: Props) => {
  return (
    <button className={props.class} data-tooltip={props.content}>
      {props.icon}
    </button>
  );
};
