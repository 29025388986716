import axiosInstance from "../axios";

class ExerciseDataService {
  getAll() {
    return axiosInstance.get("/studentui/exercises");
  }

  get(id: any) {
    return axiosInstance.get(`/studentui/exercises/${id}`);
  }

  create(data: any) {
    return axiosInstance.post("/studentui/exercises", data);
  }

  update(id: any, data: any) {
    return axiosInstance.put(`/studentui/exercises/${id}`, data);
  }

  delete(id: any) {
    return axiosInstance.delete(`/studentui/exercises/${id}`);
  }
}

export default new ExerciseDataService();
