import { Link, useParams } from "react-router-dom";
//import { useState} from "react";
import { useTranslation } from "react-i18next";
import { useSubmissionContext } from "../context/SubmissionContext";
import { useEffect, useState } from "react";
import SubmissionExerciseDataService from "../axios/services/SubmissionExerciseDataService";

export const OrientationBar = () => {
  const { t } = useTranslation();
  let { submissionExerciseId } = useParams();
  const submissionContext = useSubmissionContext();
  const con = new RegExp("/assignments/[0-9]+");
  const [assessment, setAssessment] = useState();

  var id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

  useEffect(() => {
    const activeAssignment = submissionContext.openSubmissions.find((assignment) => assignment.id === parseInt(id));
    if (activeAssignment !== undefined) {
      localStorage.setItem("assignmentName", activeAssignment.assignment.name);
      localStorage.setItem("assignmentId", id);
    }

    if (window.location.href.indexOf("/assessments-mc/") !== -1) {
      SubmissionExerciseDataService.getSubmissionExercise(submissionExerciseId)
        .then((response) => {
          let assessment = response.data.data;
          setAssessment(assessment.exercise.name);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  return (
    <div>
      <nav
        className="navbar is-white fixed-top navbar-expand-lg"
        style={{ margin: "0.5% 0%" }}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand text-dark">
          {window.location.href.indexOf("/modelling-tool") !== -1 ? (
            <p className="navbar-item has-text-weight-bold">{t("modellingTool")}</p>
          ) : (
            <Link to={"/assignments"} className=" navbar-item has-text-weight-bold">
              {t("assignments")}
            </Link>
          )}
          {con.test(window.location.href.toString()) ? (
            <>
              {" "}
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <p className="navbar-item has-text-weight-bold"> {`${localStorage.getItem("assignmentName")}`}</p>
            </>
          ) : (
            " "
          )}
          {window.location.href.indexOf("/assessments-mc/") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assignments/${localStorage.assignmentId}`} className="navbar-item has-text-weight-bold">
                {`${localStorage.getItem("assignmentName")}   `}
              </Link>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <p className="navbar-item has-text-weight-bold">{assessment}</p>
            </>
          ) : (
            ""
          )}

          {window.location.href.indexOf("/impressum") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/impressum`} className="navbar-item has-text-weight-bold">
                {t("Impressum")}
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      </nav>
    </div>
  );
};
