import { AuthenticationController } from "./AuthenticationController";
import { UserRoleType } from "../../types/UserRoleType";
import { useState } from "react";

type Props = {};

const CHANGE_USER_ROLE_TYPE_ENABLED = true;

export const AuthenticationPage = (_props: Props) => {
  const [userRoleType, setUserRoleType] = useState<UserRoleType>(UserRoleType.STUDENT);

  return (
    <AuthenticationController
      changeRoleTypeEnabled={CHANGE_USER_ROLE_TYPE_ENABLED}
      userRoleType={userRoleType}
      setUserRoleType={setUserRoleType}
    />
  );
};
