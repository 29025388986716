import { createContext, useContext } from "react";

interface IModelUnderstandingContext {
  setSubmissionId: any;
  setExerciseId: any;
  toggleModal: any;
  toggleModalProceed: any;
  setAnswers: any;
  setAssessmentMap: any;
}

const defaultState: IModelUnderstandingContext = {
  setSubmissionId: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
  setExerciseId: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
  toggleModal: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
  toggleModalProceed: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
  setAnswers: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
  setAssessmentMap: () => {
    console.warn("ModelUnderstandingContext: not in scope.");
  },
};

export const ModelUnderstandingContext = createContext<IModelUnderstandingContext>(defaultState);
export const useModelUnderstandingContext = () => useContext(ModelUnderstandingContext);
