import { ReactElement, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSubmissionContext } from "../../../context/SubmissionContext";
import { Submission } from "../../../types/Submission";

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const SubmittedSubmissionCard = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const submissionContext = useSubmissionContext();

  const history = useNavigate();
  useEffect(() => {
    submissionContext.fetchSubmittedSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFeedbackCard = async (submissionid: number) => {
    history(`/assignments/${submissionid}`);
  };

  const getSubmissionTableItem = (submission: Submission): ReactElement => {
    return (
      <tr
        key={submission.id}
        onClick={
          true
            ? () => {
                openFeedbackCard(submission.id);
              }
            : undefined
        }
      >
        <th>{submission.assignment.name}</th>
        <td className="is-hidden-mobile">{submission.assignment.quickAccess}</td>
        <td className="is-hidden-mobile">
          {new Date(submission.updatedAt).toLocaleDateString(i18n.language, dateOptions)}
        </td>

        <td>
          <button className="button is-primary">{t("View")}</button>
        </td>
      </tr>
    );
  };

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{t("SubmittedSubmissionsCardHeader")}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column has-text-weight-light is-italic">{t("SubmittedSubmissionsCardBody")}</div>
          </div>
          <div className="columns">
            <div className="column has-text-left">
              {submissionContext.submittedSubmissions.length > 0 && (
                <div className="table-container">
                  <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                    <thead className="is-italic">
                      <tr>
                        <td>{t("SubmittedSubmissionsCardTableRowName")}</td>
                        <td>{t("AccessCode")}</td>
                        <td className="is-hidden-mobile">{t("SubmittedSubmissionsCardTableRowCreated")}</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {submissionContext.submittedSubmissions.map((submission) => {
                        return getSubmissionTableItem(submission);
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {submissionContext.submittedSubmissions.length === 0 && (
                <p className="is-size-7">{t("SubmittedSubmissionsCardTableRowEmpty")} </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubmittedSubmissionCard;
