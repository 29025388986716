import { ChangeEvent, useCallback } from "react";

import { UserRoleType } from "../../../types/UserRoleType";
import { useTranslation } from "react-i18next";

type Props = {
  type: UserRoleType;
  setUserRoleType: (type: UserRoleType) => void;
};

export const ChooseUserRoleTypeComponent = (props: Props) => {
  const { t } = useTranslation(["auth"]);

  const changeUserRoleType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.setUserRoleType(event.target.value as UserRoleType);
    },
    [props],
  );

  return (
    <>
      <div className="columns">
        <div className="column has-text-centered is-centered">
          <label className="label has-text-weight-normal has-text-centered is-unselectable">
            {t("ChooseUI", { ns: "auth" })}
          </label>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <label className="radio" htmlFor={"answer"}>
            <input
              className="radio mr-2 pr-2"
              type="radio"
              name="chosenUI"
              value={UserRoleType.STUDENT}
              checked={props.type === UserRoleType.STUDENT}
              onChange={changeUserRoleType}
            />
            {t("StudentUI", { ns: "auth" })}
          </label>
        </div>
        <div className="column">
          <label className="radio ml-4 pl-4">
            <input
              className="radio mr-2 pr-2"
              type="radio"
              name="chosenUI"
              value={UserRoleType.AUTHOR}
              checked={props.type === UserRoleType.AUTHOR}
              onChange={changeUserRoleType}
            />
            {t("AuthorUI", { ns: "auth" })}
          </label>
        </div>
      </div>
    </>
  );
};
