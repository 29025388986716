import { debounce } from "debounce";
import { ReactNode, useEffect, useRef, useState } from "react";
import { FeedbackModelEditorModalContext } from "./FeedbackModelEditorModalContext";

interface Props {
  children?: ReactNode;
}

export const FeedbackModelEditorModalContextProvider = (props: Props) => {
  const modalRef = useRef(null);
  const modalBodyRef = useRef<HTMLElement>(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const [modalVisibleFeedback, setModalVisibleFeedback] = useState<boolean>(false);
  const [submissionExerciseId, setSubmissionExerciseId] = useState<number>(-1);
  const [modelId, setModelId] = useState<number>(-1);

  const updateSize = debounce(() => {
    if (modalBodyRef.current) {
      setSize({
        width: modalBodyRef.current.offsetWidth,
        height: modalBodyRef.current.offsetHeight,
      });
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    if (!modalVisibleFeedback) {
      setSubmissionExerciseId(-1);
      setModelId(-1);
    }
  }, [modalVisibleFeedback]);

  const toggleModalFeedback = () => {
    setModalVisibleFeedback(!modalVisibleFeedback);
    updateSize();
  };

  return (
    <FeedbackModelEditorModalContext.Provider
      value={{
        submissionExerciseId,
        modelId,
        setSubmissionExerciseId,
        setModelId,
        toggleModalFeedback,
      }}
    >
      {props.children}
      <div>
        <div ref={modalRef} className={"fullscreenmodal modal" + (modalVisibleFeedback ? " is-active" : "")}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              {modalVisibleFeedback ? (
                <button className="delete" aria-label="close" onClick={toggleModalFeedback}></button>
              ) : (
                <div></div>
              )}
            </header>
            <section
              ref={modalBodyRef}
              className="modal-card-body m-0 p-0"
              style={{ width: "100%", overflowY: "hidden" }}
            >
              {modalVisibleFeedback && submissionExerciseId !== -1 && modelId !== -1 ? (
                <iframe
                  src={
                    window?._env_?.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    window?._env_?.REACT_APP_EDITOR_UI_PORT +
                    "/feedback?modelId=" +
                    modelId +
                    "&submissionExerciseId=" +
                    submissionExerciseId +
                    "&zoomDisabled=true"
                  }
                  width={size.width}
                  height={size.height}
                  title="modellingtool"
                ></iframe>
              ) : (
                ""
              )}
            </section>
          </div>
        </div>
      </div>
    </FeedbackModelEditorModalContext.Provider>
  );
};
