import { ChangeEvent } from "react";
import { LoginNameInputFieldComponent } from "./components/LoginNameInputFieldComponent";
import { LoginPasswordInputFieldComponent } from "./components/LoginPasswordInputFieldComponent";
import { useTranslation } from "react-i18next";

type Props = {
  loginName: string;
  setLoginName: (value: string) => void;
  loginPassword: string;
  setLoginPassword: (value: string) => void;
  login: () => void;
};

export const LoginView = (props: Props) => {
  const { t } = useTranslation(["auth"]);

  const onChangeLoginName = (e: ChangeEvent<HTMLInputElement>) => {
    props.setLoginName(e.target.value);
  };

  const onChangeLoginPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setLoginPassword(e.target.value);
  };

  return (
    <>
      <div className="columns">
        <div className="column">
          <LoginNameInputFieldComponent loginName={props.loginName} onChangeLoginName={onChangeLoginName} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <LoginPasswordInputFieldComponent
            loginPassword={props.loginPassword}
            onChangeLoginPassword={onChangeLoginPassword}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <button onClick={props.login} className="button is-primary is-fullwidth">
            {t("logIn", { ns: "auth" })}
          </button>
        </div>
      </div>
    </>
  );
};
