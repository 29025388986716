import "./i18n/react-i18next";
import "./index.sass";
import "./theme/modal.sass";
import "./theme/theme.sass";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ExerciseModelEditorModalContextProvider } from "./context/ExerciseModelEditorModalContextProvider";
import { FeedbackModelEditorModalContextProvider } from "./context/FeedbackModelEditorModalContextProvider";
import ModelContextProvider from "./context/ModelContextProvider";
import { ModelUnderstandingContextProvider } from "./context/ModelUnderstandingContextProvider";
import { NotificationContextProvider } from "./context/NotificationContextProvider";
import { PreviewModelEditorModalContextProvider } from "./context/PreviewModelEditorModalContextProvider";
import { SessionContextProvider } from "./context/SessionContextProvider";
import { SubmissionContextProvider } from "./context/SubmissionContextProvider";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    _env_: any;
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <SubmissionContextProvider>
      <SessionContextProvider>
        <NotificationContextProvider>
          <ModelContextProvider>
            <ExerciseModelEditorModalContextProvider>
              <FeedbackModelEditorModalContextProvider>
                <PreviewModelEditorModalContextProvider>
                  <ModelUnderstandingContextProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </ModelUnderstandingContextProvider>
                </PreviewModelEditorModalContextProvider>
              </FeedbackModelEditorModalContextProvider>
            </ExerciseModelEditorModalContextProvider>
          </ModelContextProvider>
        </NotificationContextProvider>
      </SessionContextProvider>
    </SubmissionContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
