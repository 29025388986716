import axiosInstance from "../axios";

export class SubmissionExerciseDataService {
  getAllSubmissionsExercise(id: any) {
    return axiosInstance.get(`/studentui/submissionexercises`, {
      params: { exerciseId: id },
    });
  }

  get(id: any) {
    return axiosInstance.get(`/studentui/submissionexercises/${id}/assessments`);
  }

  createEventSource(id: number) {
    return new EventSource(`${axiosInstance.defaults.baseURL}/studentui/submissionexercises/${id}/assessments/stream`, {
      withCredentials: true,
    });
  }

  create(id: any) {
    return axiosInstance.post(`/studentui/submissionexercises/${id}/assessments`);
  }

  createSubmissionAssignment() {
    return axiosInstance.post(`/studentui/submissions`);
  }

  createSubmissionExercise(data: any) {
    return axiosInstance.post(`/studentui/submissionexercises`, data);
  }

  getSubmissionExercise(id: any) {
    return axiosInstance.get(`/studentui/submissionexercises/${id}`);
  }

  updateSubmissionExercise(id: any, data: any) {
    return axiosInstance.put(`/studentui/submissionexercises/${id}`, data);
  }

  getAllSubmissions() {
    return axiosInstance.get("/studentui/submissions");
  }

  submitAssignment(id: any, data: any) {
    return axiosInstance.put(`/studentui/submissions/${id}`, data);
  }

  getAllExerciseSubmissions() {
    return axiosInstance.get("/studentui/submissionexercises");
  }

  getSubmittedSubmissions() {
    return axiosInstance.get(`/studentui/submissions`, {
      params: { submitted: true },
    });
  }

  deleteSubmission(id: any) {
    return axiosInstance.delete(`/studentui/submissions/${id}`);
  }
}
export default new SubmissionExerciseDataService();
