import { AuthenticationType } from "../../../types/AuthenticationType";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  authenticationType: AuthenticationType;
  onChangeAuthenticationType: (type: AuthenticationType) => void;
  additionalStyles: string;
};

export const LoginRegisterTabComponent = (props: Props) => {
  const { t } = useTranslation(["auth"]);
  return (
    <nav className={"breadcrumb " + props.additionalStyles}>
      <ul>
        <li
          className={classNames({
            "is-active": props.authenticationType === AuthenticationType.LOGIN,
          })}
        >
          <a onClick={() => props.onChangeAuthenticationType(AuthenticationType.LOGIN)}>{t("logIn", { ns: "auth" })}</a>
        </li>
        <li
          className={classNames({
            "is-active": props.authenticationType === AuthenticationType.REGISTER,
          })}
        >
          <a onClick={() => props.onChangeAuthenticationType(AuthenticationType.REGISTER)}>
            {t("signUp", { ns: "auth" })}
          </a>
        </li>
      </ul>
    </nav>
  );
};
