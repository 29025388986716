import NewAssignment from "./assignment.card";

export const Assignment = () => {
  return (
    <div className="section">
      <div className="columns">
        <div className="column">
          <NewAssignment />
        </div>
      </div>
    </div>
  );
};
