import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  proceedAction: any;
  exerciseType: string;
}

export const ConfirmModelingTool = (props: Props) => {
  const { t } = useTranslation();
  const [confirmCardVisible, setConfirmCardVisible] = useState(false);

  const showConfirmCard = () => {
    setConfirmCardVisible(true);
  };

  const proceedConfirmCard = () => {
    if (confirmCardVisible) {
      props.proceedAction();
      setConfirmCardVisible(false);
    }
  };

  const closeConfirmCard = () => {
    setConfirmCardVisible(false);
  };

  return (
    <>
      {confirmCardVisible && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card" style={{ width: "40%", height: "30%" }}>
            <header className="modal-card-head">
              <p className="modal-card-title">{t("confirmationRequest")}</p>
              <button className="delete" aria-label="close" onClick={closeConfirmCard}></button>
            </header>
            {props.exerciseType === "creation" ? (
              <section className="modal-card-body">{t("closeModelingTool")}</section>
            ) : (
              <section className="modal-card-body">{t("closeModelUnderstandingExercise")}</section>
            )}
            <footer className="modal-card-foot">
              <button className="button is-danger " onClick={closeConfirmCard}>
                {t("cancel")}
              </button>
              <button className="button is-primary" onClick={proceedConfirmCard}>
                {t("proceedClose")}
              </button>
            </footer>
          </div>
        </div>
      )}

      <button
        className="delete"
        aria-label="close"
        onClick={() => {
          showConfirmCard();
        }}
      ></button>
    </>
  );
};
