import { AccessCodeCard } from "./components/AccessCodeCard";
import { Navbar } from "../../components/NavigationBar";
import { OpenSubmissionsCard } from "./components/OpenSubmissionsCard";
// import { QuestionnaireCard } from "./components/QuestionnaireCard";
import SubmittedSubmissionCard from "./components/SubmittedSubmissionsCard";

export const AssignmentsPage = () => {
  return (
    <>
      <Navbar />
      <div className="section">
        <div className="columns">
          <div className="column">
            <AccessCodeCard />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <OpenSubmissionsCard />
          </div>
          <div className="column">
            <SubmittedSubmissionCard />
          </div>
        </div>
      </div>
    </>
  );
};
