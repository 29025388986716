import { ReactElement, useEffect, useState } from "react";

import { getTimeDifferenceInDays } from "@kea-mod/common";
import { useTranslation } from "react-i18next";

export type Props = {
  endDate: Date;
};

const UPDATE_INTERVALL: number = 60000;

export const RemainingTimeTag = (props: Props) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [dayDifference, setDayDifference] = useState<number>(5);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, UPDATE_INTERVALL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setDayDifference(getTimeDifferenceInDays(currentTime, props.endDate));
  }, [currentTime, props.endDate]);

  const getOverThreeDaysTag = (): ReactElement => {
    return <span className="tag is-success">{t("RemainingTimeTagLabel", { count: Math.ceil(dayDifference) })}</span>;
  };

  const getBetweenThreeDaysAndOneDayTag = (): ReactElement => {
    return (
      <span className="tag is-info">
        {t("RemainingTimeTagLabelLessThan", {
          count: Math.ceil(dayDifference),
        })}
      </span>
    );
  };

  const getLessThanOneDayTag = (): ReactElement => {
    return (
      <span className="tag is-warning">
        {t("RemainingTimeTagLabelLessThan", {
          count: Math.ceil(dayDifference),
        })}
      </span>
    );
  };

  const getExpiredTag = (): ReactElement => {
    return <span className="tag is-danger">{t("RemainingTimeTagLabelExpired")}</span>;
  };

  if (dayDifference > 3) {
    return getOverThreeDaysTag();
  }
  if (dayDifference <= 3 && dayDifference > 1) {
    return getBetweenThreeDaysAndOneDayTag();
  }
  if (dayDifference <= 1 && dayDifference > 0) {
    return getLessThanOneDayTag();
  }
  return getExpiredTag();
};
