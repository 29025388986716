export class Session {
  private static instance: Session;

  private constructor() {}

  public static getInstance(): Session {
    if (!Session.instance) {
      Session.instance = new Session();
    }

    return Session.instance;
  }

  setUniqueToken = (value: string) => {
    localStorage.setItem("unique-token", value);
  };
  getUniqueToken = () => {
    return localStorage.getItem("unique-token");
  };
  setAuthor = (value: string) => {
    localStorage.setItem("author", JSON.stringify(value));
  };
  getAuthor = () => {
    const author = localStorage.getItem("author");
    if (author) {
      return JSON.parse(author);
    }
    return "";
  };
  setStudent = (value: string) => {
    localStorage.setItem("student", JSON.stringify(value));
  };
  getStudent = () => {
    const student = localStorage.getItem("student");
    if (student) {
      return JSON.parse(student);
    }
    return "";
  };
}
