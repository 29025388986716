import axiosInstance from "../axios";

export class SubmissionExerciseDataService {
  get(id: any, ui: string) {
    return axiosInstance.get(`/${ui}/submissionexercises/${id}/assessments`);
  }

  getSubmissionExercise(id: any, ui: string) {
    return axiosInstance.get(`/${ui}/submissionexercises/${id}`);
  }
}
export default new SubmissionExerciseDataService();
