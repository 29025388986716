import axiosInstance from "../axios";

class AssignmentDataService {
  getAll() {
    return axiosInstance.get("/studentui/assignments");
  }

  get(id: any) {
    return axiosInstance.get(`/studentui/assignments/${id}`);
  }

  getQuickAccess(id: any) {
    return axiosInstance.get(`/studentui/quickaccess/${id}`);
  }

  create(data: any) {
    return axiosInstance.post("/studentui/assignments", data);
  }

  update(id: any, data: any) {
    return axiosInstance.put(`/studentui/assignments/${id}`, data);
  }

  delete(id: any) {
    return axiosInstance.delete(`/studentui/assignments/${id}`);
  }
}
export default new AssignmentDataService();
