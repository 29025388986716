export type Assessment = {
  autoAssessment: boolean;
  comment: string;
  createdAt: string;
  gradings: Object[];
  id: number;
  maxTotalScore: string;
  schemaId: number;
  status: string;
  submissionExerciseId: number;
  totalscore: string;
  updatedAt: string;
};

export const emptyAssessment: Assessment = {
  autoAssessment: false,
  comment: "",
  createdAt: "",
  gradings: [],
  id: -1,
  maxTotalScore: "10.0",
  schemaId: -1,
  status: "",
  submissionExerciseId: -1,
  totalscore: "0.0",
  updatedAt: "",
};
