import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaRedo } from "react-icons/fa";
import ExerciseDataService from "../axios/services/ExerciseDataService";
import { Tooltip } from "../common/Tooltip";
import { useModelContext } from "../context/ModelContext";
import { useExerciseModelEditorModalContext } from "../context/ExerciseModelEditorModalContext";
import { usePreviewModelEditorModalContext } from "../context/PreviewModelEditorModalContext";
import { useModelUnderstandingContext } from "../context/ModelUnderstandingContext";
import { useSubmissionContext } from "../context/SubmissionContext";
import { emptyAssignment } from "../types/Assignment";
import { Exercise } from "../types/Exercise";
import { ConfirmButton } from "./ConfirmButton";

function useForceUpdate() {
  const [_value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

export const NewAssignment = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string }; //submissionId
  const forceUpdate = useForceUpdate();
  const [assignment, setAssignment] = useState(emptyAssignment);
  const [userId, setUserId] = useState(-1);
  const submissionContext = useSubmissionContext();
  const modelContext = useModelContext();
  const exerciseModelEditorContext = useExerciseModelEditorModalContext();
  const previewModelEditorContext = usePreviewModelEditorModalContext();
  const [assessmentMap, setAssessmentMap] = useState<any>({});

  const [submission, setSubmission] = useState<any>({});
  const [sum_totalscore, setSum_totalscore] = useState(0.0);
  const [sum_maxscore, setSum_maxscore] = useState(0);
  const [openEventSources, setOpenEventSources] = useState<Map<number, EventSource>>(new Map<number, EventSource>());

  const navigate = useNavigate();
  const modelUnderstandingContext = useModelUnderstandingContext();
  //const understandingModalVisible = modelUnderstandingContext.modalVisible;

  window.onbeforeunload = () => {
    openEventSources.forEach((e) => e.close());
  };

  useEffect(() => {
    const awaitSubmission = async () => {
      const submission = await submissionContext.getSubmission(id);
      setAssignment(submission.assignment);
      setSubmission(submission);

      setUserId(submission.userId);
    };
    awaitSubmission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await FillFeedbackMap();
    };
    const handleEvent = async (event: any) => {
      if (event.data === "modelSaved") {
        fetchData();
      }
    };
    fetchData();
    window.addEventListener("message", handleEvent, false);
    return () => {
      openEventSources.forEach((e) => e.close());
      window.removeEventListener("message", handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const FillFeedbackMap = async () => {
    console.log("Check if new feedback available");
    if (assignment.id !== -1) {
      for (let x = 0; x < assignment.exercises.length; x++) {
        let ex = assignment.exercises[x];

        getSubmissionforExid(ex.id);
      }
    } else {
    }
  };

  useEffect(() => {
    computeSumScores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentMap, FillFeedbackMap]);

  const computeSumScores = () => {
    const keys = Object.keys(assessmentMap);
    let totalmax = 0.0;
    let maxmax = 0.0;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let obj = assessmentMap[key];

      if (obj.gradings[0].resultType === "RELATIVE") {
        totalmax = totalmax + parseFloat((obj.totalscore * obj.maxTotalScore).toFixed(2));
      } else {
        totalmax = totalmax + parseFloat(obj.totalscore.toFixed(2));
      }
    }
    totalmax = parseFloat(totalmax.toFixed(2));
    for (let k = 0; k < assignment.exercises.length; k++) {
      let ex = assignment.exercises[k];
      maxmax = maxmax + Number(ex.maxTotalScore);
    }

    setSum_totalscore(totalmax);
    setSum_maxscore(maxmax);
  };

  const getSubmissionforExid = async (exid: any) => {
    let submissions = await submissionContext.getAllSubmissionsExercise(exid);
    let subm_len = submissions.length;
    let exists = false;
    let index = 0;
    // check if submission exists for current user
    for (let i = 0; i < subm_len; i++) {
      if (submissions[i].userId === userId) {
        exists = true;

        index = i;

        break;
      }
    }

    if (exists) {
      var exsubmission = submissions[index];

      getAssessmentforExSubmission(exsubmission, exid);
    }
  };

  const getAssessmentforExSubmission = async (exsub: any, exid: any) => {
    try {
      let eventSource;
      if (!(eventSource = openEventSources.get(exsub.id)))
        eventSource = submissionContext.getAssessmentEventSource(exsub.id);
      const eventSources = openEventSources;

      eventSources.set(exsub.id, eventSource);
      setOpenEventSources(eventSources);

      eventSource.onmessage = (event: MessageEvent<any>) => {
        const data = JSON.parse(event.data).data;

        if ("message" in data && (data.message === `none` || data.message === `incomplete`)) return;
        const mapCopy = assessmentMap;

        mapCopy[exid] = data;
        setAssessmentMap(mapCopy);
        forceUpdate();
      };
      eventSource.onerror = (event: MessageEvent<any>) => {
        console.log("Error with eventSource: " + event.data);
      };
    } catch (e) {}
  };

  const setAssessment = (exerciseType: string, exerciseId: number) => {
    if (exerciseType === "understanding") {
      checkExerciseSubmissionUnderstanding(exerciseId);
    } else {
      if (exerciseType === "creation") {
        checkExerciseSubmissionCreation(exerciseId);
      }
    }
  };

  const checkExerciseSubmissionUnderstanding = async (exerciseId: any) => {
    let submissions = await submissionContext.getAllSubmissionsExercise(exerciseId); // all submissions for the exercise

    let subm_len = submissions.length;

    let exists = false;
    let index = 0;
    let submissionExerciseId = -1;

    // check if submission exists for current user
    for (let i = 0; i < subm_len; i++) {
      if (submissions[i].userId === userId) {
        exists = true;
        index = i;
        submissionExerciseId = submissions[i].id;
        break;
      }
    }

    if (exists) {
      if (submissions[index].type === "understanding") {
        let answers = submissions[index].submissionUnderstanding;
        modelUnderstandingContext.setAssessmentMap(assessmentMap);
        modelUnderstandingContext.setAnswers(answers);
        modelUnderstandingContext.setSubmissionId(submissionExerciseId);
        modelUnderstandingContext.setExerciseId(exerciseId);
        modelUnderstandingContext.toggleModal();
      }
    } else {
      createEmptySubmissionExerciseUnderstanding(exerciseId);
    }
  };

  const createEmptySubmissionExerciseUnderstanding = async (exerciseId: any) => {
    let ansCheckedList: { answerId: any; selectionState: string }[] = [];

    ExerciseDataService.get(exerciseId)
      .then((response) => {
        let answers = response.data.data.understanding.answer;

        for (let i = 0; i < answers.length; i++) {
          let ans = {
            answerId: answers[i].id,
            selectionState: "none",
          };
          ansCheckedList.push(ans);
        }
        createAndToggleModal(exerciseId, ansCheckedList);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createAndToggleModal = async (exerciseId: any, ansCheckedList: any) => {
    let data = {
      exerciseId: exerciseId,
      submissionId: parseInt(id),
      type: "understanding",
      answers: ansCheckedList,
    };

    let submissionExercise = await submissionContext.createSubmissionExercise(data);
    modelUnderstandingContext.setAssessmentMap(assessmentMap);
    modelUnderstandingContext.setAnswers(submissionExercise.submissionUnderstanding);
    modelUnderstandingContext.setSubmissionId(submissionExercise.id);
    modelUnderstandingContext.setExerciseId(exerciseId);
    modelUnderstandingContext.toggleModal();
  };

  const checkExerciseSubmissionCreation = async (exid: any) => {
    let submissions = await submissionContext.getAllSubmissionsExercise(exid); // all submissions for the exercise

    let subm_len = submissions.length;

    let exists = false;
    let index = 0;

    // check if submission exists for current user
    for (let i = 0; i < subm_len; i++) {
      if (submissions[i].userId === userId) {
        exists = true;
        index = i;
        break;
      }
    }

    if (exists) {
      if (submissions[index].type === "creation") {
        let modelId = submissions[index].studentSolution[0].id;
        startModellingEditor(exid, modelId);
      }
    } else {
      createEmptyModelAndSubmissionExerciseCreation(exid);
    }
  };

  const startModellingEditor = (exid: any, model_Id: any) => {
    let exerciseId = parseInt(exid);
    console.log("modelID: " + model_Id + "\n userID: " + userId + "\n exerciseid: " + exerciseId);
    if (!submission.submitted) {
      exerciseModelEditorContext.setAssessmentMap(assessmentMap);
      exerciseModelEditorContext.setModelId(model_Id);
      exerciseModelEditorContext.setTaskId(exerciseId);
      exerciseModelEditorContext.toggleModal();
    } else {
      previewModelEditorContext.setModelId(model_Id);
      previewModelEditorContext.setExportEnabled(true);
      previewModelEditorContext.toggleModalPreview();
    }
  };

  const createEmptyModelAndSubmissionExerciseCreation = async (exerciseId: number) => {
    const data_submission = {
      submissionId: parseInt(id),
      exerciseId: exerciseId,
      type: "creation",
    };

    let submissionExercise = await submissionContext.createSubmissionExercise(data_submission);

    const data_model = {
      graphData: {
        graph: {
          cells: [],
          timesCopied: 0,
          dragStartPosition: {},
        },
        type: "PN",
      },
      name: "undefined",
      type: "studentSolution",
      parentModel: "submission_exercise",
    };

    let model = await modelContext.create(data_model, exerciseId);

    updateEmptyModel(submissionExercise.id, model, exerciseId); // update field parentId with submissionExerciseId
    startModellingEditor(exerciseId, model.id);
  };

  const updateEmptyModel = (submissionExerciseId: any, model: any, exerciseId: number) => {
    const data_model = {
      graphData: model.graphData,
      name: model.name,
      type: model.type,
      parentId: parseInt(submissionExerciseId),
      parentModel: model.parentModel,
    };

    modelContext.updateModel(model.id, data_model, exerciseId);
  };

  const getTypeToolTip = (exerciseType: string) => {
    let transvar = "";
    if (exerciseType === "creation") transvar = "toolTipMC";
    else if (exerciseType === "understanding") transvar = "toolTipMU";
    else return <div></div>;

    return (
      <Tooltip
        icon={<FaInfoCircle />}
        class="button is-white has-tooltip-multiline has-tooltip-bottom"
        content={t(transvar)}
      ></Tooltip>
    );
  };

  const getTableItem = (exercise: Exercise) => {
    return (
      <tr key={exercise.id}>
        <td>{exercise.name}</td>
        <td className="is-hidden-mobile">{t(exercise.language.name)}</td>
        <td className="is-hidden-mobile">
          {getTypeToolTip(exercise.type)}
          {t(exercise.type)}
        </td>
        {/* 
        <td className="is-hidden-mobile">
          {getTrimmedSubstringWithDots(b64DecodeUnicode(exercise.description), 40)}
        </td>
    */}
        {exercise.type === "creation" ? (
          <td className="has-text-right">
            {(assessmentMap[exercise.id] && exercise.directFeedback === true) ||
            (assessmentMap[exercise.id] && submission.submitted === true)
              ? parseFloat(
                  (assessmentMap[exercise.id].totalscore * assessmentMap[exercise.id].maxTotalScore).toFixed(2),
                ) +
                " / " +
                parseFloat(assessmentMap[exercise.id].maxTotalScore.toFixed(2))
              : "- / " + parseFloat(Number(exercise.maxTotalScore).toFixed(2))}
          </td>
        ) : (
          <td className="has-text-right">
            {(assessmentMap[exercise.id] && exercise.directFeedback === true) ||
            (assessmentMap[exercise.id] && submission.submitted === true)
              ? parseFloat(assessmentMap[exercise.id].totalscore.toFixed(2)) +
                " / " +
                parseFloat(assessmentMap[exercise.id].maxTotalScore.toFixed(2))
              : "- / " + parseFloat(Number(exercise.maxTotalScore).toFixed(2))}
          </td>
        )}

        {exercise.feedUp !== null && exercise.feedUp !== "" ? (
          <td className="is-hidden-mobile">
            <Tooltip
              icon={<FaInfoCircle />}
              class="button is-white has-tooltip-multiline has-tooltip-bottom"
              content={t(exercise.feedUp)}
            ></Tooltip>
          </td>
        ) : (
          <td className="is-hidden-mobile"></td>
        )}

        <td className="has-text-right">
          {!submission.submitted ? (
            <div className="button is-success" onClick={() => setAssessment(exercise.type, Number(exercise.id))}>
              {t("AssignmentEditButton")}
            </div>
          ) : (
            <div></div>
          )}
        </td>
        <td className="has-text-left">
          {(() => {
            // show active results button
            // if feedback is there and submission is submitted or directFeedback is on while not submitted
            if (
              (exercise.id in assessmentMap && submission.submitted) ||
              (exercise.id in assessmentMap && exercise.directFeedback && !submission.submitted)
            ) {
              return (
                <button
                  className="button is-info ml-5"
                  onClick={assessmentMap[exercise.id] ? () => showFeedback(exercise) : undefined}
                >
                  {t("ShowFeedback")}
                </button>
              );
            }
            // If submission is not submitted and directFeedback is off, there should be no button
            else if (!submission.submitted && !exercise.directFeedback) {
              return <div></div>;
            }
            // Otherwise the Results button is disabled
            else
              return (
                <button className="button is-info ml-5" disabled={true}>
                  {t("ShowFeedback")}
                </button>
              );
          })()}

          {/*
          {exercise.id in assessmentMap ? (
            <button
              className="button is-info ml-5"
              disabled={
                !(exercise.id in assessmentMap) || !(exercise.directFeedback === true || submission.submitted === true)
              }
              onClick={assessmentMap[exercise.id] ? () => showFeedback(exercise) : undefined}
            >
              {t("ShowFeedback")}
            </button>
          ) : (
            <button className="button is-info ml-5" disabled={true}>
              {t("ShowFeedback")}
            </button>
          )}
          */}
        </td>
      </tr>
    );
  };

  const getTable = () => {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("AssignmentTableRowName")}</td>
              <td className="is-hidden-mobile">{t("AssignmentTableRowLanguage")}</td>
              <td className="is-hidden-mobile">{t("exerciseType")}</td>
              <td className="has-text-right">{t("Score")}</td>
              <td className="has-text-right">{t("feedUp")}</td>
              <td className="has-text-right"></td>
              <td className="has-text-left">
                <Tooltip
                  icon={<FaInfoCircle />}
                  class="button is-white has-tooltip-multiline has-tooltip-bottom"
                  content={t("CheckfornewFeedback")}
                ></Tooltip>
                <div className="button is-primary" onClick={() => FillFeedbackMap()}>
                  <FaRedo />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {userId !== -1
              ? assignment.exercises.map((exercise: Exercise) => {
                  return getTableItem(exercise);
                })
              : undefined}
            <tr>
              <td></td>
              <td className="is-hidden-mobile"></td>
              <td className="is-hidden-mobile has-text-weight-bold">{t("totalAssignment")}:</td>
              <td className="has-text-right has-text-weight-bold">
                {sum_totalscore}/{sum_maxscore}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const showFeedback = async (ex: any) => {
    let submissions = await submissionContext.getAllSubmissionsExercise(ex.id);

    let subm_len = submissions.length;
    let index = 0;

    // check if submission exists for current user
    for (let i = 0; i < subm_len; i++) {
      if (submissions[i].userId === userId) {
        index = i;
        break;
      }
    }
    var sub = submissions[index];
    let submissionExerciseId = sub.id;

    if (ex.type === "creation") {
      navigate(`/assessments-mc/${submissionExerciseId}/${id}/${ex.id}`);
    } else if (ex.type === "understanding") {
      navigate(`/assessments-mu/${submissionExerciseId}/${id}/${ex.id}`);
    }
  };

  const submit = async (submissionId: any) => {
    let data = {
      submitted: true,
    };

    await submissionContext.submitAssignment(submissionId, data);
    window.location.href = "/assignments";
  };

  return (
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="box">
              <div className="columns">
                <div className="column">
                  <div className="title is-4">{assignment.name}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="subtitle is-6">{assignment.description}</div>
                </div>
              </div>
              <div className="columns">
                <div className="column">{getTable()}</div>
              </div>

              <div className="columns">
                <div className="column">
                  {!submission.submitted ? (
                    <ConfirmButton
                      id={id}
                      name={assignment.name}
                      proceedAction={submit}
                      question={`submitConfirmationSubmission`}
                      explanation={`submitConfirmationSubmissionIrreversible`}
                      buttonType="button is-primary"
                      buttonName={t("AssignmentSubmitButton")}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewAssignment;
