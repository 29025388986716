import { createContext, useContext } from "react";

interface IPreviewModelEditorModalContext {
  modelId: any;
  exportEnabled: any;
  setModelId: (id: any) => void;
  setExportEnabled: (value: any) => void;
  toggleModalPreview: () => void;
}

const defaultState: IPreviewModelEditorModalContext = {
  modelId: -1,
  exportEnabled: false,
  setExportEnabled: () => {
    console.warn("PreviewModelEditorModalContext: not in scope.");
  },
  setModelId: () => {
    console.warn("PreviewModelEditorModalContext: not in scope.");
  },
  toggleModalPreview: () => {
    console.warn("PreviewModelEditorModalContext: not in scope.");
  },
};

export const PreviewModelEditorModalContext = createContext<IPreviewModelEditorModalContext>(defaultState);
export const usePreviewModelEditorModalContext = () => useContext(PreviewModelEditorModalContext);
