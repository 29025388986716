interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}
/* eslint-disable */
enum LogLevel {
  TEST = "TEST",
  PRODUCTION = "PRODUCTION",
}
const logLevel = process.env.REACT_APP_LOGLEVEL ? (process.env.REACT_APP_LOGLEVEL as LogLevel) : LogLevel.PRODUCTION;
interface LoggerFunctions {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

const NO_OP: LogFn = (_message?: any, ..._optionalParams: any[]) => {};

export class ConsoleLogger implements LoggerFunctions {
  readonly log: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor() {
    this.log = logLevel === LogLevel.TEST ? console.log.bind(console) : NO_OP;
    this.warn = logLevel === LogLevel.TEST ? console.warn.bind(console) : NO_OP;
    this.error = console.error.bind(console);
  }
}

export const logger = new ConsoleLogger();
