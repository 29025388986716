type Props = {
  modelId: any;
  submissionExerciseId: any;
};

export const ModelCardComponentMC = (props: Props) => {
  return (
    <div className="column is-full">
      <div className="m-0 p-0 is-clipped is-relative" style={{ height: "650px" }}>
        <iframe
          scrolling="no"
          className="m-0 p-0"
          style={{
            width: "100%",
            height: "100%",
          }}
          src={
            (window as any)._env_?.REACT_APP_EDITOR_UI_URL +
            ":" +
            (window as any)._env_?.REACT_APP_EDITOR_UI_PORT +
            "/feedback" +
            "?modelId=" +
            props.modelId +
            "&submissionExerciseId=" +
            props.submissionExerciseId +
            "&zoomDisabled=true"
          }
          title="modellingtool"
        ></iframe>
      </div>
    </div>
  );
};
