import { debounce } from "debounce";
import { ReactNode, useEffect, useRef, useState } from "react";
import { ExerciseModelEditorModalContext } from "./ExerciseModelEditorModalContext";
import { ConfirmModelingTool } from "../components/ConfirmModelingTool";
import { useNotificationContext } from "../context/NotificationContext";
import { NotificationMessage } from "../types/Notification";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "./SessionContext";

interface Props {
  children?: ReactNode;
}

export const ExerciseModelEditorModalContextProvider = (props: Props) => {
  const notificationContext = useNotificationContext();
  const sessionContext = useSessionContext();
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const modalBodyRef = useRef<HTMLElement>(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number>(-1);
  const [assessmentMap, setAssessmentMap] = useState<any>({});
  const [modelId, setModelId] = useState<number>(-1);

  const updateSize = debounce(() => {
    if (modalBodyRef.current) {
      setSize({
        width: modalBodyRef.current.offsetWidth,
        height: modalBodyRef.current.offsetHeight,
      });
    }
  }, 50);

  const getModelSavedMessage = (): NotificationMessage => {
    return {
      displayTime: 2500,
      message: t("modelSaved"),
      type: "is-primary",
    };
  };

  useEffect(() => {
    const handleEvent = (_event: { data: string; origin: string }) => {
      const { data, origin } = _event;
      if (origin === window?._env_?.REACT_APP_EDITOR_UI_URL && data === "modelSaved") {
        toggleModal();
        notificationContext.setNotification(getModelSavedMessage());
      }
    };

    window.addEventListener("message", handleEvent, false);
    return function cleanup() {
      window.removeEventListener("message", handleEvent);
    };
  });

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    if (!modalVisible) {
      setTaskId(-1);
      setModelId(-1);
    }
  }, [modalVisible]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
    updateSize();

    if (modalVisible) {
      delete assessmentMap[taskId];
    }
  };

  const toggleModalProceed = () => {
    setModalVisible(!modalVisible);
    updateSize();
  };

  const userInfo = () => {
    if (sessionContext.givenName && sessionContext.familyName && sessionContext.matriculationNumber) {
      return (
        <div className="navbar-item has-text-weight-bold" style={{ flexGrow: 1, justifyContent: "center" }}>
          {"Student: " +
            sessionContext.givenName +
            " " +
            sessionContext.familyName +
            ", " +
            t("matriculationNumber") +
            ": " +
            sessionContext.matriculationNumber}
        </div>
      );
    } else return "";
  };

  return (
    <ExerciseModelEditorModalContext.Provider
      value={{
        taskId,
        modelId,
        assessmentMap,
        toggleModal,
        setAssessmentMap,
        setTaskId,
        setModelId,
      }}
    >
      {props.children}
      <div>
        <div ref={modalRef} className={"fullscreenmodal modal" + (modalVisible ? " is-active" : "")}>
          <div className="modal-background"></div>
          <div className="modal-card" style={{ width: "98%", height: "100%" }}>
            <header className="modal-card-head">
              {modalVisible ? (
                <ConfirmModelingTool exerciseType="creation" proceedAction={toggleModalProceed} />
              ) : (
                <div></div>
              )}
              {userInfo()}
            </header>
            <section
              ref={modalBodyRef}
              className="modal-card-body m-0 p-0"
              style={{ width: "100%", overflowY: "hidden" }}
            >
              {modalVisible && taskId !== -1 && modelId !== -1 ? (
                <iframe
                  src={
                    window?._env_?.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    window?._env_?.REACT_APP_EDITOR_UI_PORT +
                    "/exercises?exerciseId=" +
                    taskId +
                    "&modelId=" +
                    modelId
                  }
                  width={size.width}
                  height={size.height}
                  title="modellingtool"
                ></iframe>
              ) : (
                ""
              )}
            </section>
          </div>
        </div>
      </div>
    </ExerciseModelEditorModalContext.Provider>
  );
};
