import React from "react";
import { WithChildren } from "@kea-mod/common";

export enum Horizontal {
  LEFT,
  RIGHT,
}

export enum Vertical {
  TOP,
  BOTTOM,
}

interface Props extends WithChildren {
  alignHorizontal?: Horizontal;
  alignVertical?: Vertical;
  horizontalSpace?: string;
  verticalSpace?: string;
}

export const ButtonGroup = ({
  children,
  alignHorizontal = Horizontal.RIGHT,
  alignVertical = Vertical.TOP,
  horizontalSpace = "2rem",
  verticalSpace = "2rem",
}: Props) => {
  const styles: React.CSSProperties = {
    position: "absolute",
  };
  alignHorizontal === Horizontal.RIGHT ? (styles.right = horizontalSpace) : (styles.left = horizontalSpace);
  alignVertical === Vertical.TOP ? (styles.top = verticalSpace) : (styles.bottom = verticalSpace);

  return (
    <>
      <div style={styles}>
        <p className="buttons">{children}</p>
      </div>
    </>
  );
};
