import { useEffect, useState } from "react";

import { b64DecodeUnicode } from "@kea-mod/common";
import { useTranslation } from "react-i18next";
import MDEditor from "@uiw/react-md-editor";
import ExerciseDataService from "../axios/services/ExerciseDataService";
import { useSubmissionContext } from "../context/SubmissionContext";
import { ModelCardComponentMU } from "@kea-mod/common/components/ModelCardComponentMU";
import { ConfirmModelingTool } from "../components/ConfirmModelingTool";
import { useNotificationContext } from "../context/NotificationContext";
import { useSessionContext } from "../context/SessionContext";
import { NotificationMessage } from "../types/Notification";

type Props = {
  submissionId: number;
  exerciseId: number;
  answers: any[];
  modalVisible: boolean;
  toggleModal: Function;
  toggleModalProceed: Function;
};

export const ExerciseUnderstandingComponent = (props: Props) => {
  const submissionContext = useSubmissionContext();
  const notificationContext = useNotificationContext();
  const sessionContext = useSessionContext();
  const { t } = useTranslation();
  const [name, setName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [question, setQuestion] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const [modelId, setModelId] = useState<any>(-1);
  const [answersCheckedList, setAnswersCheckedList] = useState<any>([]);

  useEffect(() => {
    getCurrentExercise(props.exerciseId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentExercise = (exerciseId: any) => {
    ExerciseDataService.get(exerciseId)
      .then((response) => {
        let res = response.data.data;
        setName(res.name);
        setDescription(b64DecodeUnicode(res.description));
        setQuestion(res.understanding.question);
        sortAndSetAnswers(res.understanding.answer);
        setModelId(res.understanding.models.inputs[0]?.id);

        if (props.answers && props.answers.length) {
          sortAndSetExistingAnswers(props.answers); // set answers based on last submission
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const sortAndSetAnswers = (answers: any) => {
    const sortedAnswers = [...answers].sort((a, b) => a.id - b.id); // sort by Id ascending
    setAnswers(sortedAnswers);
  };

  const sortAndSetExistingAnswers = (existingAnswers: any) => {
    const sortedExistingAnswers = [...existingAnswers].sort((a, b) => a.answerId - b.answerId); // sort by answerId ascending
    let ansCheckedList: any = [];
    for (let i = 0; i < sortedExistingAnswers.length; i++) {
      let ans = {
        id: sortedExistingAnswers[i].id,
        selectionState: sortedExistingAnswers[i].selectionState,
      };
      ansCheckedList.push(ans);
    }
    setAnswersCheckedList(ansCheckedList);
  };

  const handleCheckAnswer = (index: any, selectionState: any) => {
    let newArray: any = [...answersCheckedList];
    newArray[index].selectionState = selectionState;
    setAnswersCheckedList(newArray);
  };

  const getExerciseSavedMessage = (): NotificationMessage => {
    return {
      displayTime: 2500,
      message: t("exerciseSaved"),
      type: "is-primary",
    };
  };

  const submitExercise = () => {
    let data = {
      answers: answersCheckedList,
    };

    if (submissionContext.updateSubmissionExercise(props.submissionId, data)) {
      notificationContext.setNotification(getExerciseSavedMessage());
      window.parent.postMessage("modelSaved", "*");
    }
    props.toggleModalProceed();
  };

  const getAnswerRow = (answer: any, index: any) => {
    return (
      <tr key={answer.id}>
        <td>{answer.text}</td>
        <td className="has-text-right">
          <label className="radio mr-5">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"true" + index}
              checked={answersCheckedList[index].selectionState === "true"}
              onChange={() => handleCheckAnswer(index, "true")}
            />
            {t("true")}
          </label>
          <label className="radio mr-5">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"false" + index}
              checked={answersCheckedList[index].selectionState === "false"}
              onChange={() => handleCheckAnswer(index, "false")}
            />
            {t("false")}
          </label>
          <label className="radio">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"notSpecified" + index}
              checked={answersCheckedList[index].selectionState === "none"}
              onChange={() => handleCheckAnswer(index, "none")}
            />
            {t("noAnswer")}
          </label>
        </td>
      </tr>
    );
  };

  const userInfo = () => {
    if (sessionContext.givenName && sessionContext.familyName && sessionContext.matriculationNumber) {
      return (
        <div className="navbar-item has-text-weight-bold" style={{ flexGrow: 1, justifyContent: "center" }}>
          {"Student: " +
            sessionContext.givenName +
            " " +
            sessionContext.familyName +
            ", " +
            t("matriculationNumber") +
            ": " +
            sessionContext.matriculationNumber}
        </div>
      );
    } else return "";
  };

  return (
    <div className={"fullscreenmodal modal" + (props.modalVisible ? " is-active" : "")}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: "98%", height: "100%" }}>
        <header className="modal-card-head mb-0">
          {props.modalVisible ? (
            <ConfirmModelingTool exerciseType="understanding" proceedAction={props.toggleModalProceed} />
          ) : (
            <div></div>
          )}
          <label className="label pl-6">
            {t("WorkingOnExercise")} »{name}«
          </label>
          {userInfo()}
        </header>
        <section className="modal-card-body mt-0 pt-0">
          <div className="section">
            <div className="columns is-centered">
              <div className="column" data-color-mode="light">
                <MDEditor.Markdown source={description} />
              </div>
            </div>

            {modelId !== -1 ? (
              <div className="mb-4">
                <ModelCardComponentMU modelId={modelId} />
              </div>
            ) : (
              <div> </div>
            )}
            <div className="columns">
              <div className="column pb-0 mb-0">{question}</div>
            </div>
            <div className="table-container"></div>
            <div className="columns is-centered">
              <div className="column">{t("noAnswerText")}</div>
            </div>
            <table className="table pt-0 mt-0 is-striped is-narrow is-hoverable is-fullwidth">
              <tbody>
                {answers.map((answer: any, index: any) => {
                  return getAnswerRow(answer, index);
                })}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger" onClick={() => props.toggleModal()}>
            {t("cancel")}
          </button>
          <button className="button is-primary" onClick={submitExercise}>
            {t("Save")}
          </button>
        </footer>
      </div>
    </div>
  );
};
