// Solution from https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings

export const b64EncodeUnicode = (str: string): string | undefined => {
  try {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  } catch {
    console.error("There was an error b64-encoding 0the following string: ", str);
    return undefined;
  }
};

export const b64DecodeUnicode = (str: string): string | undefined => {
  try {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
  } catch {
    console.error("There was an error b64-decoding the following string: ", str);
    return undefined;
  }
};
