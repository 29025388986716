import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
type Props = {};

export const ImpressumPage = (_props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function back() {
    navigate(`/assignments/`);
  }
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="box">
                <div className="columns">
                  <div className="column">
                    <div className="title is-4">{t("Impressum")}</div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="subtitle is-6">xxxxx</div>
                  </div>
                </div>
              </div>
              <div>
                <button className="button is-danger" onClick={() => back()}>
                  {t("back")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
