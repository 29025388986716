export const isNumber = (value: string) => {
  return !isNaN(+value);
};

export const hasSixDigits = (value: string) => {
  return getLength(value) === 6 ? true : false;
};

export const getLength = (value: string) => {
  return value.length;
};

export const getTrimmedSubstringWithDots = (value: string, size: number): string => {
  const length = getLength(value);
  if (size < length) {
    return getTrimmedSubstring(value, size).concat("...");
  }
  return value;
};

export const getTrimmedSubstring = (value: string, size: number): string => {
  const length = getLength(value);
  if (size < length) {
    return value.substring(0, size);
  }
  return value;
};
