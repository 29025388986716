import { Model, emptyModel } from "../types/Model";
import { createContext, useContext } from "react";

interface IModelContext {
  models: Model[];
  model: Model;
  update: any;
  create: any;
  delete: any;
  updateModel: any;
  setModel: any;
}

const defaultState: IModelContext = {
  models: [],
  model: emptyModel,
  update: () => console.warn("ModelContext: not in scope."),
  create: () => console.warn("ModelContext: not in scope."),
  delete: () => console.warn("ModelContext: not in scope."),
  updateModel: () => console.warn("ModelContext: not in scope."),
  setModel: () => console.warn("ModelContext: not in scope."),
};

export const ModelContext = createContext<IModelContext>(defaultState);
export const useModelContext = () => useContext(ModelContext);
