import { ReactNode, useEffect, useState } from "react";
import AuthService from "../axios/services/AuthenticationService";
import { routes } from "../common/routes";
import { UserInfo } from "../types/UserInfo";
import { SessionContext } from "./SessionContext";

interface Props {
  children?: ReactNode;
}

export const SessionContextProvider = (props: Props) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);
  const [givenName, setGivenName] = useState<string | undefined>(undefined);
  const [familyName, setFamilyName] = useState<string | undefined>(undefined);
  const [matriculationNumber, setMatriculationNumber] = useState<string | undefined>(undefined);

  /* useEffect(() => {
    updateSession();
    // eslint-disable-next-line
  }, []); */

  const redirectToStudentUI = () => {
    const url = window?._env_?.REACT_APP_STUDENT_UI_URL;
    const port = window?._env_?.REACT_APP_STUDENT_UI_PORT;
    if (!url || !port) return;
    window.location.href = `${url}:${port}${routes.assignments}`;
  };

  const redirectToLogin = () => {
    const url = window?._env_?.REACT_APP_LANDINGPAGE_URL;
    const port = window?._env_?.REACT_APP_LANDINGPAGE_PORT;
    if (!url || !port) return;
    window.location.href = `${url}:${port}`;
  };

  /*
  const redirectToAuthorUI = () => {
    const url = window?._env_?.REACT_APP_AUTHOR_UI_URL;
    const port = window?._env_?.REACT_APP_AUTHOR_UI_PORT;
    if (!url || !port) return;
    window.location.href = `${url}:${port}/assignments`;
  };
  */

  useEffect(() => {
    const redirect = (cRole: String | undefined) => {
      const r = [routes.modellingTool, routes.assessmentsMU, routes.assessmentsMC, routes.assignments];
      console.log(`Path: ${r.includes(`/${window.location.pathname.split(`/`)[1]}`)}`);
      if (
        (window.location.pathname === `/` || !r.includes(`/${window.location.pathname.split(`/`)[1]}`)) &&
        cRole !== undefined
      )
        redirectToStudentUI();
      else if (cRole === undefined) redirectToLogin();
    };
    updateSession().then((cookieRole) => {
      console.log("Role: ", cookieRole);
      // TODO: fix
      // to develop locally, the if-statement needs to be fixed
      // otherwise deactivate the redirect function (you will have to remove unused stuff as well)
      console.log(process.env);
      if (process.env.NODE_ENV !== "development") redirect(cookieRole);
    });
  });

  const getCookie = (name: string) => {
    return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ``;
  };

  const getRoleCookie = (cookie: UserInfo) => {
    return cookie.roles[0];
  };

  const getNameCookie = (cookie: UserInfo) => {
    return cookie.user;
  };

  const updateSession = async () => {
    AuthService.getUsers().then((res) => {
      const userInfo = res.data.data.user;

      if (userInfo.givenName) {
        setGivenName(userInfo.givenName);
      }

      if (userInfo.familyName) {
        setFamilyName(userInfo.familyName);
      }

      if (userInfo.matriculationNumber) {
        setMatriculationNumber(userInfo.matriculationNumber);
      }
    });

    console.log("updating session");
    const uriCookie = decodeURIComponent(getCookie(`user.info`));
    let cookieRole = undefined;
    console.log("uriCookie: ", uriCookie);
    if (!uriCookie) return cookieRole;

    const userCookie = JSON.parse(uriCookie);

    cookieRole = getRoleCookie(userCookie);
    if (cookieRole) {
      setRole(cookieRole);
    }

    const cookieName = getNameCookie(userCookie);
    if (cookieName) {
      setName(cookieName);
    }
    console.log("UPDATING SESSION", cookieRole, cookieName);
    return cookieRole;
  };

  return (
    <SessionContext.Provider
      value={{
        name,
        role,
        givenName,
        familyName,
        matriculationNumber,
        updateSession,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
