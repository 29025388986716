import { createContext, useContext } from "react";

interface ISessionContext {
  name: string | undefined;
  role: string | undefined;
  givenName: string | undefined;
  familyName: string | undefined;
  matriculationNumber: string | undefined;
  updateSession: () => void;
}

const defaultState: ISessionContext = {
  name: "",
  role: "",
  givenName: "",
  familyName: "",
  matriculationNumber: "",
  updateSession: () => {
    console.warn("SessionContext: not in scope.");
  },
};

export const SessionContext = createContext<ISessionContext>(defaultState);
export const useSessionContext = () => useContext(SessionContext);
