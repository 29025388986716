import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  secondaryPassword: string;
  onChangeSecondaryPassword: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ConfirmLoginPasswordInputFieldComponent = (props: Props) => {
  const { t } = useTranslation(["auth"]);
  return (
    <div className="field">
      <label className="label has-text-centered is-unselectable">{t("confirmPassword", { ns: "auth" })}</label>
      <input
        className="input"
        type="password"
        value={props.secondaryPassword}
        onChange={props.onChangeSecondaryPassword}
      />
    </div>
  );
};
