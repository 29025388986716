import { createContext, useContext } from "react";

import { Submission } from "../types/Submission";

interface ISubmissionContext {
  openSubmissions: Submission[];
  submittedSubmissions: Submission[];
  fetchOpenSubmissions: () => void;
  fetchSubmittedSubmissions: () => void;
  fetchAllSubmissions: () => void;
  deleteSubmission: (id: any) => void;
  getAssessment: (id: any) => any;
  getAssessmentEventSource: (id: any) => any;
  getSubmission: (id: any) => any;
  getAllSubmissionsExercise: (id: any) => any;
  createSubmissionExercise: (data: any) => any;
  getSubmissionExercise: (id: any, data: any) => void;
  updateSubmissionExercise: (id: any, data: any) => any;
  submitAssignment: (id: any, data: any) => any;
}

const defaultState: ISubmissionContext = {
  openSubmissions: [],
  submittedSubmissions: [],

  fetchOpenSubmissions: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  fetchSubmittedSubmissions: () => {
    console.warn("SubmissionContext: not in scope.");
  },

  fetchAllSubmissions: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  deleteSubmission: (_id: number) => {
    console.warn("SubmissionContext: not in scope.");
  },
  getAssessment: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  getAssessmentEventSource: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  getSubmission: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  getAllSubmissionsExercise: () => {
    console.warn("SubmissionContext: not in scope.");
  },
  createSubmissionExercise: (_data: any) => {
    console.warn("SubmissionContext: not in scope.");
  },
  getSubmissionExercise: (_id: any, _data: any) => {
    console.warn("SubmissionContext: not in scope.");
  },
  updateSubmissionExercise: (_id: any, _data: any) => {
    console.warn("SubmissionContext: not in scope.");
  },
  submitAssignment: () => {
    console.warn("SubmissionContext: not in scope.");
  },
};

export const SubmissionContext = createContext<ISubmissionContext>(defaultState);
export const useSubmissionContext = () => useContext(SubmissionContext);
