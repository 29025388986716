import { Navigate, Route, Routes } from "react-router-dom";
import "./App.sass";
import { Suspense } from "react";
import { routes } from "./common/routes";
import { Assignment } from "./components/assignment.component";
import { FeedbackCardMC } from "@kea-mod/common/components/feedback-mc.card";
import { FeedbackCardMU } from "@kea-mod/common/components/feedback-mu.card";
import { ModellingTool } from "./components/ModellingTool";
import { Navbar } from "./components/NavigationBar";
import { AssignmentsPage } from "./pages/assignments/AssignmentsPage";
import { AuthenticationPage } from "./pages/authentication/AuthenticationPage";
import { ImpressumPage } from "./pages/authentication/Impressum";
function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={`${routes.assignments}`} element={<AssignmentsPage />} />
        <Route
          path={`${routes.impressum}`}
          element={
            <>
              <Navbar />
              <ImpressumPage />
            </>
          }
        />
        <Route
          path={`${routes.assignments}/:id`}
          element={
            <>
              <Navbar />
              <Assignment />
            </>
          }
        />

        <Route path={"/auth"} element={<AuthenticationPage />} />

        <Route
          path={`${routes.assessmentsMC}/:submissionExerciseId/:subid/:exerciseId`}
          element={
            <>
              <Navbar />
              <FeedbackCardMC UI={"studentui"} />
            </>
          }
        />

        <Route
          path={`${routes.assessmentsMU}/:submissionExerciseId/:subid/:exerciseId`}
          element={
            <>
              {" "}
              <Navbar />
              <FeedbackCardMU UI={"studentui"} />
            </>
          }
        />

        <Route
          path={`${routes.modellingTool}`}
          element={
            <>
              {" "}
              <Navbar />
              <Suspense fallback={<div>Loading...</div>}>
                <ModellingTool />
              </Suspense>
            </>
          }
        />

        <Route path="/" element={<Navigate replace to="/assignments" />} />
      </Routes>
    </Suspense>
  );
}

export default App;
