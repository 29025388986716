import { debounce } from "debounce";
import { ReactNode, useEffect, useRef, useState } from "react";
import { PreviewModelEditorModalContext } from "./PreviewModelEditorModalContext";

interface Props {
  children?: ReactNode;
}

export const PreviewModelEditorModalContextProvider = (props: Props) => {
  const modalRef = useRef(null);
  const modalBodyRef = useRef<HTMLElement>(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const [modalVisiblePreview, setModalVisiblePreview] = useState<boolean>(false);
  const [modelId, setModelId] = useState<number>(-1);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  const updateSize = debounce(() => {
    if (modalBodyRef.current) {
      setSize({
        width: modalBodyRef.current.offsetWidth,
        height: modalBodyRef.current.offsetHeight,
      });
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    if (!modalVisiblePreview) {
      setModelId(-1);
    }
  }, [modalVisiblePreview]);

  const toggleModalPreview = () => {
    setModalVisiblePreview(!modalVisiblePreview);
    updateSize();
  };

  return (
    <PreviewModelEditorModalContext.Provider
      value={{
        modelId,
        exportEnabled,
        setModelId,
        setExportEnabled,
        toggleModalPreview,
      }}
    >
      {props.children}
      <div>
        <div ref={modalRef} className={"fullscreenmodal modal" + (modalVisiblePreview ? " is-active" : "")}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              {modalVisiblePreview ? (
                <button className="delete" aria-label="close" onClick={toggleModalPreview}></button>
              ) : (
                <div></div>
              )}
            </header>
            <section
              ref={modalBodyRef}
              className="modal-card-body m-0 p-0"
              style={{ width: "100%", overflowY: "hidden" }}
            >
              {modalVisiblePreview && modelId !== -1 ? (
                <iframe
                  src={
                    window?._env_?.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    window?._env_?.REACT_APP_EDITOR_UI_PORT +
                    "/models/preview?modelId=" +
                    modelId +
                    "&exportEnabled=" +
                    exportEnabled +
                    "&showButtons=true" +
                    "&zoomDisabled=true"
                  }
                  width={size.width}
                  height={size.height}
                  title="modellingtool"
                ></iframe>
              ) : (
                ""
              )}
            </section>
          </div>
        </div>
      </div>
    </PreviewModelEditorModalContext.Provider>
  );
};
