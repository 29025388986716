import axiosInstance from "../axios";

export class AuthService {
  register(data: any) {
    return axiosInstance.post("/auth/signup", data);
  }

  login(data: any) {
    return axiosInstance.post("/auth/signin", data);
  }

  signOut() {
    return axiosInstance.post("/auth/signout");
  }

  getUsers() {
    return axiosInstance.get("/users");
  }
}
export default new AuthService();
