import { ReactElement, useState } from "react";

import SubmissionDataService from "../axios/services/SubmissionDataService";
import SubmissionExerciseDataService from "../axios/services/SubmissionExerciseDataService";
import { Exercise } from "../types/Exercise";
import { Submission } from "../types/Submission";
import { SubmissionContext } from "./SubmissionContext";

interface Props {
  children: ReactElement;
}

export const SubmissionContextProvider = (props: Props) => {
  const [openSubmissions, setOpenSubmissions] = useState<Submission[]>([]);
  const [submittedSubmissions, setSubmittedSubmissions] = useState<Submission[]>([]);

  const fetchAllSubmissions = () => {
    fetchOpenSubmissions();
    fetchSubmittedSubmissions();
  };

  const fetchSubmittedSubmissions = () => {
    SubmissionExerciseDataService.getSubmittedSubmissions()
      .then((response) => {
        if (response.data.success) {
          const submissions: Submission[] = response.data.data;
          setSubmittedSubmissions(submissions);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING SUBMISSIONS HERE. IF LOGIN ERROR, LOGOUT. ELSE WARNING NOTIFICATION AND A MESSAGE TO TRY AGAIN? */
        }
      })
      .catch((e) => {
        /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING SUBMISSIONS HERE. IF LOGIN ERROR, LOGOUT. ELSE WARNING NOTIFICATION AND A MESSAGE TO TRY AGAIN? */
        console.warn(e);
      });
  };

  const fetchOpenSubmissions = async () => {
    SubmissionExerciseDataService.getAllSubmissions()
      .then((response) => {
        if (response.data.success) {
          const submissions: Submission[] = response.data.data;
          return submissions;
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING SUBMISSIONS HERE. IF LOGIN ERROR, LOGOUT. ELSE WARNING NOTIFICATION AND A MESSAGE TO TRY AGAIN? */
          return [];
        }
      })
      .then(async (submissions) => {
        if (!submissions) return submissions;
        for (const submission of submissions) {
          submission.exercises = await fetchExercisesForSubmission(submission.id);
        }
        return submissions;
      })
      .then((submissions) => {
        if (!submissions) return submissions;
        setOpenSubmissions(submissions);
        return submissions;
      })
      .catch((e) => {
        /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING SUBMISSIONS HERE. IF LOGIN ERROR, LOGOUT. ELSE WARNING NOTIFICATION AND A MESSAGE TO TRY AGAIN? */
        console.warn(e);
        return [];
      });
  };

  const deleteSubmission = (id: number) => {
    SubmissionExerciseDataService.deleteSubmission(id)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          fetchOpenSubmissions();
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING SUBMISSION HERE. */
        }
      })
      .catch((e) => {
        /** TODO SHOULD HANDLE ERROR DELETING SUBMISSIONS. */
        console.warn(e);
      });
  };

  const fetchExercisesForSubmission = async (id: number): Promise<Array<Exercise>> => {
    return new Promise<Array<Exercise>>((resolve, reject) => {
      SubmissionDataService.get(id).then((response) => {
        if (response.data.success) {
          // is here because the dummy assignment is malformed. every assignment should have a field exercises
          if (!response.data.data.assignment.exercises) return resolve([]);

          return resolve(response.data.data.assignment.exercises);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(`could not fetch exercise for id: ${id}`);
        }
      });
    });
  };

  const getAssessment = async (id: number): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.get(id)
        .then((response) => {
          if (response.data.success) {
            return resolve(response.data.data);
          } else {
            /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
            reject(new Map());
          }
        })
        .catch((_e) => {
          reject(new Map());
        });
    });
  };

  const getAssessmentEventSource = (id: any) => {
    return SubmissionExerciseDataService.createEventSource(id);
  };

  const getSubmission = async (id: number): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionDataService.get(id).then((response) => {
        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject("no submission found");
        }
      });
    });
  };

  const getAllSubmissionsExercise = async (id: number): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.getAllSubmissionsExercise(id).then((response) => {
        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject({});
        }
      });
    });
  };

  const createSubmissionExercise = async (data: any) => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.createSubmissionExercise(data).then((response) => {
        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(-1);
        }
      });
    });
  };

  const getSubmissionExercise = (id: any) => {
    SubmissionExerciseDataService.getSubmissionExercise(id)
      .then((response) => {
        if (response.data.success) {
          console.log(response);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL GETTING SUBMISSION HERE. */
        }
      })
      .catch((e) => {
        /** TODO SHOULD HANDLE ERROR GETTING SUBMISSIONS. */
        console.warn(e);
      });
  };

  const updateSubmissionExercise = (id: any, data: any) => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.updateSubmissionExercise(id, data).then((response) => {
        if (response.data.success) {
          return resolve(true);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL UPDATING SUBMISSION HERE. */
          reject({});
        }
      });
    });
  };

  const submitAssignment = (id: any, data: any) => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.submitAssignment(id, data).then((response) => {
        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject({});
        }
      });
    });
  };

  return (
    <SubmissionContext.Provider
      value={{
        openSubmissions,
        submittedSubmissions,
        createSubmissionExercise,
        getSubmissionExercise,
        updateSubmissionExercise,
        fetchOpenSubmissions,
        fetchSubmittedSubmissions,
        fetchAllSubmissions,
        deleteSubmission,
        getAssessment,
        getAssessmentEventSource,
        getSubmission,
        submitAssignment,
        getAllSubmissionsExercise,
      }}
    >
      {props.children}
    </SubmissionContext.Provider>
  );
};
