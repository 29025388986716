import { useTranslation } from "react-i18next";

interface Props {
  setOpen: any;
  setClose: any;
  name: any;
  question: any;
  explanation: any;
  buttonCancelText: any;
  buttonProceedText: any;
}

export function ConfirmDialog({
  setOpen,
  setClose,
  name,
  question,
  explanation,
  buttonCancelText,
  buttonProceedText,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: "40%", height: "40%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title">{t("confirmationRequest")}</p>
          <button className="delete" aria-label="close" onClick={setClose}></button>
        </header>
        <section className="modal-card-body">
          {t(`${question}`)}
          <ul style={{ listStyleType: "disc", margin: "5%" }}>
            <li>{name}</li>
          </ul>
          {t(`${explanation}`)}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger " onClick={setClose}>
            {t(`${buttonCancelText}`)}
          </button>
          <button className="button is-primary" onClick={setOpen}>
            {t(`${buttonProceedText}`)}
          </button>
        </footer>
      </div>
    </div>
  );
}
