import { useState } from "react";
import { ConfirmDialog } from "./ConfirmationModalCard";

interface Props {
  buttonName: any;
  buttonType: string | undefined;
  proceedAction: any;
  question: any;
  explanation: any;
  id: any;
  name: any;
}

export const ConfirmButton = (props: Props) => {
  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    name: "",
  });

  const handleClick = (id: any, name: any) => {
    setPopup({
      show: true,
      id,
      name,
    });
  };

  const SetOpen = () => {
    if (popup.show && popup.id) {
      props.proceedAction(popup.id);
      setPopup({
        show: false,
        id: null,
        name: "",
      });
    }
  };

  const SetClose = () => {
    setPopup({
      show: false,
      id: null,
      name: "",
    });
  };

  return (
    <>
      {popup.show && (
        <ConfirmDialog
          setOpen={SetOpen}
          setClose={SetClose}
          name={popup.name}
          question={props.question}
          explanation={props.explanation}
          buttonCancelText="cancel"
          buttonProceedText="proceed"
        />
      )}
      <button
        className={props.buttonType}
        onClick={() => {
          handleClick(props.id, props.name);
        }}
      >
        {props.buttonName}
      </button>
    </>
  );
};
