import { useCallback, useEffect, useState } from "react";
import { APISignInBody } from "../../types/APISignIn";
import { APISignUpBody, APISignUpResponse } from "../../types/APISignUp";
import { NotificationMessage, NotificationMessageType } from "../../types/Notification";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../axios/services/AuthenticationService";
import { Session } from "../../common/Session";
import { useNotificationContext } from "../../context/NotificationContext";
import { useSessionContext } from "../../context/SessionContext";
import { AuthenticationType } from "../../types/AuthenticationType";
import { UserRoleType } from "../../types/UserRoleType";
import { LanguageButtons } from "./components/LanguageButtons";
import { LoginRegisterTabComponent } from "./components/LoginRegisterTabComponent";
import { LoginView } from "./LoginView";
import { RegisterView } from "./RegisterView";

type Props = {
  changeRoleTypeEnabled: boolean;
  userRoleType: UserRoleType;
  setUserRoleType: (value: UserRoleType) => void;
};

const getCustomNotificationMessage = (msg: string, type: NotificationMessageType): NotificationMessage => {
  return {
    displayTime: 6000000,
    message: msg,
    type: type,
  };
};

export const AuthenticationController = (props: Props) => {
  const { t } = useTranslation(["auth"]);
  const notificationContext = useNotificationContext();
  const sessionContext = useSessionContext();
  let navigate = useNavigate();

  const [authenticationType, setAuthenticationType] = useState<AuthenticationType>(AuthenticationType.LOGIN);
  const [loginName, setLoginName] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");

  const [secondaryPassword, setSecondaryPassword] = useState<string>("");

  const performLogin = useCallback(
    (body: APISignInBody) => {
      const authService = new AuthService();
      authService.login(body).then(
        (res: any) => {
          console.log("test");
          if ("response" in res) {
            if (res.response.status === 401 || res.response.status === 404) {
              notificationContext.setNotification(
                getCustomNotificationMessage(t("InvalidCredentialsNotification"), "is-danger"),
              );
            } else
              notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
            return;
          }
          if (res.data.success === true) {
            const session = Session.getInstance();
            session.setUniqueToken(body.token);

            session.setAuthor(res.data.data.roles.includes("ROLE_AUTHOR"));
            session.setStudent(res.data.data.roles.includes("ROLE_STUDENT"));
            navigate("/assignments");
          } else {
            notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
          }
        },
        (err) => {
          console.log("AuthenticationController error:");
          console.log(err);
          if (err.response.status === 401) {
            notificationContext.setNotification(
              getCustomNotificationMessage(t("InvalidCredentialsNotification"), "is-danger"),
            );
          } else notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
        },
      );
    },
    [notificationContext, sessionContext, t],
  );

  const isLoginDataValid = useCallback((): boolean => {
    if (loginName === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyNameNotification"), "is-info"));
      return false;
    }
    if (loginPassword === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyPWNotification"), "is-info"));
      return false;
    }
    return true;
  }, [loginName, loginPassword, notificationContext, t]);

  const login = useCallback(() => {
    if (!isLoginDataValid()) return;
    const requestBody = {
      token: loginName,
      password: loginPassword,
    };
    performLogin(requestBody);
  }, [loginName, loginPassword, performLogin, isLoginDataValid]);

  const performSignup = useCallback(
    (body: APISignUpBody) => {
      const authService = new AuthService();
      authService.register(body).then(
        (res) => {
          const response: APISignUpResponse = res.data;
          if (response.success) {
            login();
          } else {
            notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
          }
        },
        (err) => {
          if (err.response.status === 400) {
            notificationContext.setNotification(
              getCustomNotificationMessage(t("AlreadySignedUpNotification"), "is-danger"),
            );
          }
        },
      );
    },
    [login, notificationContext, t],
  );

  const isSignUpDataValid = useCallback((): boolean => {
    if (loginName === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyNameNotification"), "is-info"));
      return false;
    }
    if (loginPassword === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyPWNotification"), "is-info"));
      return false;
    }

    if (loginPassword !== secondaryPassword) {
      notificationContext.setNotification(getCustomNotificationMessage(t("PWsDoNotMatch"), "is-danger"));
      return false;
    }

    return true;
  }, [loginName, loginPassword, secondaryPassword, notificationContext, t]);

  const register = useCallback(() => {
    if (!isSignUpDataValid()) return;
    const roles = [];
    if (props.userRoleType === UserRoleType.AUTHOR) roles.push("author");
    if (props.userRoleType === UserRoleType.STUDENT) roles.push("student");

    let data: APISignUpBody = {
      token: loginName,
      password: loginPassword,
      roles,
    };

    performSignup(data);
  }, [loginName, loginPassword, performSignup, props.userRoleType, isSignUpDataValid]);

  const isEnterPressed = useCallback((event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return true;
    }
    return false;
  }, []);

  const onKeyDownListener = useCallback(
    (event: KeyboardEvent) => {
      if (!isEnterPressed(event)) return;
      authenticationType === AuthenticationType.LOGIN ? login() : void 0;
      authenticationType === AuthenticationType.REGISTER ? register() : void 0;
    },
    [authenticationType, isEnterPressed, login, register],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownListener);
    return () => {
      document.removeEventListener("keydown", onKeyDownListener);
    };
  }, [authenticationType, onKeyDownListener]);

  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <div className="columns">
                <div className="column is-full">
                  <LanguageButtons additionalStyles="is-right" />
                </div>
              </div>

              <div className="columns">
                <div className="column is-full is-unselectable mt-5 has-text-centered is-centered">
                  <figure className="image is-128x128 is-inline-block">
                    <img draggable="false" className="is-rounded" src="/assets/KEA-Mod_Logo_RGB_S.png" alt="KEA-Mod" />
                  </figure>
                </div>
              </div>

              <div className="columns mb-0 pb-0">
                <div className="column is-unselectable has-text-centered has-text-success mt-0 pt-0 is-size-4 has-text-weight-light">
                  {t("keamod", { ns: "auth" })}
                </div>
              </div>

              <LoginRegisterTabComponent
                additionalStyles="is-centered"
                authenticationType={authenticationType}
                onChangeAuthenticationType={setAuthenticationType}
              />

              {authenticationType === AuthenticationType.LOGIN && (
                <LoginView
                  loginName={loginName}
                  setLoginName={setLoginName}
                  loginPassword={loginPassword}
                  setLoginPassword={setLoginPassword}
                  login={login}
                />
              )}
              {authenticationType === AuthenticationType.REGISTER && (
                <RegisterView
                  loginName={loginName}
                  setLoginName={setLoginName}
                  loginPassword={loginPassword}
                  setLoginPassword={setLoginPassword}
                  secondaryPassword={secondaryPassword}
                  setSecondaryPassword={setSecondaryPassword}
                  register={register}
                  changeRoleTypeEnabled={props.changeRoleTypeEnabled}
                  userRoleType={props.userRoleType}
                  setUserRoleType={props.setUserRoleType}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
