import { ChangeEvent, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import type { UserRoleType } from "../../types/UserRoleType";
import { ChooseUserRoleTypeComponent } from "./components/ChooseUserRoleTypeComponent";
import { ConfirmLoginPasswordInputFieldComponent } from "./components/ConfirmLoginPasswordInputFieldComponent";
import { LoginNameInputFieldComponent } from "./components/LoginNameInputFieldComponent";
import { LoginPasswordInputFieldComponent } from "./components/LoginPasswordInputFieldComponent";

type Props = {
  loginName: string;
  setLoginName: (value: string) => void;
  loginPassword: string;
  setLoginPassword: (value: string) => void;
  secondaryPassword: string;
  setSecondaryPassword: (value: string) => void;
  register: () => void;
  changeRoleTypeEnabled: boolean;
  userRoleType: UserRoleType;
  setUserRoleType: (value: UserRoleType) => void;
};

export const RegisterView = (props: Props) => {
  const { t } = useTranslation(["auth"]);

  const onChangeLoginName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setLoginName(e.target.value);
    },
    [props],
  );

  const onChangeLoginPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setLoginPassword(e.target.value);
    },
    [props],
  );

  const onChangeSecondaryPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setSecondaryPassword(e.target.value);
    },
    [props],
  );

  return (
    <>
      <div className="columns">
        <div className="column">
          <LoginNameInputFieldComponent loginName={props.loginName} onChangeLoginName={onChangeLoginName} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <LoginPasswordInputFieldComponent
            loginPassword={props.loginPassword}
            onChangeLoginPassword={onChangeLoginPassword}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ConfirmLoginPasswordInputFieldComponent
            secondaryPassword={props.secondaryPassword}
            onChangeSecondaryPassword={onChangeSecondaryPassword}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-weight-normal has-text-centered is-unselectable">
          <FaExclamationTriangle className="mr-2 has-text-danger" />
          {t("BewarePW", { ns: "auth" })}
          <FaExclamationTriangle className="ml-2 has-text-danger" />
          <br />
          {t("RecommendPW", { ns: "auth" })}
        </div>
      </div>

      {props.changeRoleTypeEnabled && (
        <ChooseUserRoleTypeComponent type={props.userRoleType} setUserRoleType={props.setUserRoleType} />
      )}

      <div className="columns">
        <div className="column">
          <button onClick={props.register} className="button is-primary is-fullwidth">
            {t("signUp", { ns: "auth" })}
          </button>
        </div>
      </div>
    </>
  );
};
