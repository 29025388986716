import { ReactElement, useEffect } from "react";

import { getTimeDifferenceInMilliseconds } from "@kea-mod/common/components/Date";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSubmissionContext } from "../../../context/SubmissionContext";
import { Submission } from "../../../types/Submission";
import { RemainingTimeTag } from "./RemainingTimeTag";

export const OpenSubmissionsCard = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const submissionContext = useSubmissionContext();

  useEffect(() => {
    submissionContext.fetchOpenSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSubmission = (id: number) => {
    history(`/assignments/${id}`);
  };

  const getTableItem = (submission: Submission): ReactElement => {
    const isNotExpired =
      getTimeDifferenceInMilliseconds(new Date(), new Date(submission.assignment.endDate)) > 0 ? true : false;

    return (
      <tr
        className={`${isNotExpired ? "is-clickable" : ""}`}
        onClick={
          isNotExpired
            ? () => {
                openSubmission(submission.id);
              }
            : undefined
        }
        key={submission.id}
      >
        <th>
          {/*
          <button
            className="delete mr-2"
            onClick={(event) => {
              event.stopPropagation();
              submissionContext.deleteSubmission(submission.id);
            }}
           />
           */}
          {submission.assignment.name}
        </th>
        <td className="is-hidden-mobile">{submission.assignment.quickAccess}</td>
        <td className="is-hidden-mobile">{submission.exercises.length}</td>
        <td>
          <RemainingTimeTag endDate={new Date(submission.assignment.endDate)} />
        </td>
        <td>
          <button className="button is-primary" disabled={!isNotExpired}>
            {t("WorkOn")}
          </button>
        </td>
      </tr>
    );
  };

  const getTable = (): ReactElement => {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("openSubmissionsCardTableRowName")}</td>
              <td>{t("AccessCode")}</td>
              <td className="is-hidden-mobile">{t("openSubmissionsCardTableRowNumberOfExercises")}</td>
              <td>{t("openSubmissionsCardTableRowTimeRemaining")}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {submissionContext.openSubmissions.map((submission) => {
              return getTableItem(submission);
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getEmptyMessage = (): ReactElement => {
    return <p className="is-size-7">{t("openSubmissionsCardTableRowEmpty")} </p>;
  };

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{t("openSubmissionsCardHeader")}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column has-text-weight-light is-italic">{t("openSubmissionsCardBody")}</div>
          </div>
          <div className="columns">
            <div className="column has-text-left">
              {submissionContext.openSubmissions.length > 0 && getTable()}
              {submissionContext.openSubmissions.length === 0 && getEmptyMessage()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
