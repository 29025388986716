import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faInfo, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthService from "../axios/services/AuthenticationService";
import { Tooltip } from "../common/Tooltip";
import { useSessionContext } from "../context/SessionContext";
import { useEffect, useState } from "react";
import { OrientationBar } from "./OrientationBar";
//import { useNavigate } from "react-router-dom";
library.add(faUser, faEnvelope, faInfo);

export const Navbar = () => {
  const sessionContext = useSessionContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  //const navigate = useNavigate();
  const toggleSetIsActive = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {}, [sessionContext.role]);

  const logOut = () => {
    AuthService.signOut().then(
      (res: any) => {
        if (res.data.success === true) {
          localStorage.removeItem("unique-token");
          document.cookie = "user.id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          toggleSetIsActive();
          // TODO: Hier zur Landing Page, nicht Student UI
          const url = window?._env_?.REACT_APP_STUDENT_UI_URL;
          const port = window?._env_?.REACT_APP_STUDENT_UI_PORT;
          if (!url || !port) return;
          window.location.href = `${url}:${port}/assignments`;
        } else {
          // TODO LOGOUT ERROR
        }
      },
      (_err: any) => {
        // TODO LOGOUT ERROR
      },
    );
  };

  const goToAuthorUI = () => {
    const authorURL = window?._env_?.REACT_APP_AUTHOR_UI_URL;
    if (!authorURL) return;
    window.location.href = authorURL + "/assignments";
  };

  /*
  const goToImpressum = () => {
    navigate(`/impressum`);
  };*/

  const locationURIStringArray = window.location.pathname.split("/");

  const authorUITableItem = () => {
    if (sessionContext.role !== "author") return;

    return (
      <div className="navbar-item">
        <div className="button is-info" onClick={goToAuthorUI}>
          {t("SwitchRoleAuthor")}
        </div>
      </div>
    );
  };

  const userSessionTooltip = () => {
    return (
      <Tooltip
        icon={<FontAwesomeIcon icon="user" />}
        class="button is-white has-tooltip-multiline has-tooltip-left"
        content={t("loggedInAs") + " " + sessionContext.name}
      ></Tooltip>
    );
  };

  const userInfo = () => {
    if (sessionContext.givenName && sessionContext.familyName && sessionContext.matriculationNumber) {
      return (
        <div className="navbar-item has-text-weight-bold" style={{ flexGrow: 1, justifyContent: "center" }}>
          {"Student: " +
            sessionContext.givenName +
            " " +
            sessionContext.familyName +
            ", " +
            t("matriculationNumber") +
            ": " +
            sessionContext.matriculationNumber}
        </div>
      );
    } else return "";
  };

  return (
    <>
      <nav className="navbar is-white is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="collapse navbar-collapse flex-column ml-lg-0 ml-3" id="navbarCollapse">
          <ul className="navbar-nav">
            <div className={classNames("navbar-menu", { "is-active": isActive })}>
              <div className="navbar-brand">
                <Link onClick={toggleSetIsActive} to={"/assignments"} className="navbar-item">
                  <img src="/assets/logo.png" alt="KEA-Mod" />

                  <span className="has-text-weight-light">{t("headerTitleStudent")}</span>
                </Link>

                <button className={classNames("navbar-burger", { "is-active": isActive })} onClick={toggleSetIsActive}>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div className="navbar-start">
                <Link
                  onClick={toggleSetIsActive}
                  to={"/assignments"}
                  className={classNames("navbar-item", {
                    "has-text-weight-bold": locationURIStringArray[1] === "assignments",
                  })}
                >
                  {t("assignments")}
                </Link>

                <Link
                  onClick={toggleSetIsActive}
                  to={"/modelling-tool"}
                  className={classNames("navbar-item", {
                    "has-text-weight-bold": locationURIStringArray[1] === "modelling-tool",
                  })}
                >
                  {t("modellingTool")}
                </Link>
              </div>
            </div>
          </ul>
          <ul className="navbar-nav flex-row mb-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <OrientationBar />
              </ol>
            </nav>
          </ul>
        </div>

        {userInfo()}

        <div className="navbar-end">
          <div className="collapse navbar-collapse flex-column ml-lg-0 ml-3" id="navbarCollapse2">
            <ul className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button
                    onClick={() => {
                      toggleSetIsActive();
                      i18n.changeLanguage("de");
                    }}
                    className={classNames("button is-white mx-1", {
                      "has-text-weight-semibold": i18n.language === "de",
                      "has-text-weight-light": i18n.language !== "de",
                    })}
                  >
                    DE
                  </button>
                  <button
                    onClick={() => {
                      toggleSetIsActive();
                      i18n.changeLanguage("en");
                    }}
                    className={classNames("button is-white mx-1", {
                      "has-text-weight-semibold": i18n.language === "en",
                      "has-text-weight-light": i18n.language !== "en",
                    })}
                  >
                    EN
                  </button>
                  {userSessionTooltip()}
                </div>
              </div>
              <div className="ml-0 navbar-item">
                <a href="mailto:info@keamod.de">
                  <FontAwesomeIcon icon="envelope" />
                </a>
              </div>
              {/* 
                <div className="navbar-item" onClick={() => goToImpressum()}>
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon icon="info" />
                </a>
              </div>
              */}
              <div className="navbar-item">
                <a href="https://docs.keamod.de" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon="info" />
                </a>
              </div>

              {authorUITableItem()}
              <div className="navbar-item">
                <div className="button is-danger" onClick={logOut}>
                  {t("logOut")}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
