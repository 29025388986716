// @flow

import {
  FaArrowAltCircleDown,
  FaArrowAltCircleRight,
  FaArrowAltCircleUp,
  FaCircle,
  FaCheck,
  FaTimes,
  FaQuestionCircle,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import ExerciseDataService from "../axios/services/ExerciseDataService";
import { ModelCardComponentMU } from "./ModelCardComponentMU";
import ModelDataService from "../axios/services/ModelDataService";
import SubmissionExerciseDataService from "../axios/services/SubmissionExerciseDataService";
//import Tooltip from "../tooltip";
import { emptyAssessment } from "../types/Assessment";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { b64DecodeUnicode } from "./b64";
import MDEditor from "@uiw/react-md-editor";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
} as any;

type Props = {
  UI: string;
};

export const FeedbackCardMU = (props: Props): any => {
  let { submissionExerciseId, exerciseId } = useParams();
  const { t, i18n } = useTranslation();
  // TODO: remove, unten auch
  //let userId = localStorage.getItem("unique-token");
  const [assessment, setAssessment] = useState(emptyAssessment) as any;
  const navigate = useNavigate();
  const [feedbacks, setFeedbacks] = useState([]);
  const [modelId, setModelId] = useState(-1);
  const [submission_exercise, setSubmission_exercise] = useState({}) as any;
  const [description, setDescription] = useState("");
  const [content, setContent] = useState([]);
  const [answers, setAnswers] = useState({}) as any;
  const [update, setUpdate] = useState(false);
  const [question, setQuestion] = useState("");
  const [answersCheckedList, setAnswersCheckedList] = useState([]);
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [submissionFlag, setSubmissionFlag] = useState(false);
  const [categoryMap, setCategoryMap] = useState({}) as any;
  const [feedForwardThreshold, setFeedForwardThreshold] = useState(-1);
  const [feedForwardPos, setFeedForwardPos] = useState("");
  const [feedForwardNeg, setfeedForwardNeg] = useState("");

  useEffect(() => {
    SubmissionExerciseDataService.getSubmissionExercise(submissionExerciseId, props.UI)
      .then((response: any) => {
        let submissionExercise = response.data.data;
        setAnswersCheckedList(submissionExercise.submissionUnderstanding);
        setAnswersLoaded(true);
        setSubmission_exercise(submissionExercise);
        setSubmissionFlag(true);
        setDescription(b64DecodeUnicode(submissionExercise.exercise.description) as string);
        setFeedForwardThreshold(submissionExercise.exercise.feedForward_threshold);
        setFeedForwardPos(submissionExercise.exercise.feedForward_pos);
        setfeedForwardNeg(submissionExercise.exercise.feedForward_neg);
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var hashmap: any = {};
    for (let i = 0; i < feedbacks.length; i++) {
      var current = feedbacks[i] as any;
      var category = current.category;
      var valuation = current.valuation;
      var content = current.content;
      var positionX = current.positionX;
      var positionY = current.positionY;

      if (category in hashmap) {
        if (valuation in hashmap[category]) {
          hashmap[category][valuation].push([content, positionX, positionY]);
        } else {
          hashmap[category][valuation] = [];
          hashmap[category][valuation].push([content, positionX, positionY]);
        }
      } else {
        hashmap[category] = {};
        hashmap[category][valuation] = [];
        hashmap[category][valuation].push([content, positionX, positionY]);
      }
    }

    setCategoryMap(hashmap);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks]);

  useEffect(() => {
    SubmissionExerciseDataService.get(submissionExerciseId, props.UI)
      .then((response: any) => {
        let assessment = response.data.data;

        setAssessment(assessment);
        var Feedbacks = [] as any;
        for (var o = 0; o < assessment.feedbacksmanual.length; o++) {
          let manual = assessment.feedbacksmanual[o];
          Feedbacks.push(manual);
        }
        for (var i = 0; i < assessment.gradings.length; i++) {
          let grading = assessment.gradings[i];

          for (var a = 0; a < grading.feedbacks.length; a++) {
            let feedback = grading.feedbacks[a];
            var array = JSON.parse(feedback.content);
            setContent(array);

            if (!(feedback.category === "internal" && feedback.valuation === "OTHER")) {
              Feedbacks.push(feedback);
            }
          }
        }

        setFeedbacks(Feedbacks);
        //setManualFeedbacks(Feedbacks);
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(exerciseId);
    console.log(submissionExerciseId);
    ExerciseDataService.get(exerciseId, props.UI)
      .then((response: any) => {
        let res = response.data.data;

        setQuestion(res.understanding.question);

        setModelId(res.understanding.models.inputs[0]?.id);

        let bar: any = {};
        for (var a = 0; a < res.understanding.answer.length; a++) {
          var ans = res.understanding.answer[a];
          bar[ans.id] = { text: ans.text, id: ans.id };
        }
        setAnswers(bar);
      })
      .catch((e: any) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var bar = answers as any;
    for (var i = 0; i < content.length; i++) {
      var answer = content[i] as any;
      var status = answer["studentAnswer"];

      if (answer.id in bar) {
        bar[answer.id]["status"] = status;
      }
      setAnswers(bar);
    }
    setUpdate(!update);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, content]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    var hashmap: any = {};
    for (let i = 0; i < feedbacks.length; i++) {
      var current = feedbacks[i] as any;
      // only add feedback to hashmap if not supressed to user
      if (!current.suppressToUser) {
        var category = current.category;
        var valuation = current.valuation;
        var content = current.content;
        var positionX = current.positionX;
        var positionY = current.positionY;
        if (positionX !== 0 || positionY !== 0) {
          //Feedback of a certain element , hide it in list
          continue;
        }

        if (category in hashmap) {
          if (valuation in hashmap[category]) {
            hashmap[category][valuation].push([content, positionX, positionY]);
          } else {
            hashmap[category][valuation] = [];
            hashmap[category][valuation].push([content, positionX, positionY]);
          }
        } else {
          hashmap[category] = {};
          hashmap[category][valuation] = [];
          hashmap[category][valuation].push([content, positionX, positionY]);
        }
      }
    }

    setCategoryMap(hashmap);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks]);

  useEffect(() => {
    if (!submissionFlag) {
      return;
    } else {
    }
    ModelDataService.getModelforSubex(exerciseId, "studentSolution")
      .then((response: any) => {
        let models = response.data.data;
        for (let i = 0; i < models.length; i++) {
          let model = models[i];

          // eslint-disable-next-line eqeqeq
          if (model.parentId === parseInt(submissionExerciseId as string)) {
            setModelId(model.id);

            break;
          }
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionFlag]);

  /*
    function getTableItem(feedback) {
      if (feedback.positionX === 0 && feedback.positionY === 0 && feedback.positionId === "")
        return (
          <tr key={feedback.id}>
            <th>{feedback.category}</th>
            <th>{feedback.valuation}</th>
            <th>{feedback.content}</th>
  
            <td></td>
          </tr>
        );
    }
    */

  const getTableItemGrading = (grading: any) => {
    if (grading.resultType === "RELATIVE")
      return (
        <tr key={grading.id}>
          <th>{grading.gradingservice.name}</th>
          {/*
          <th>
            {grading.gradingservice.name}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-right"
              content={grading.gradingservice.description}
            ></Tooltip>
          </th>
          */}
          <th>{grading.result * 100} % </th>
          <th>{t("RELATIVE")} </th>
          <th>{grading.weight} %</th>
          <th>
            {parseFloat(((grading.weight / 100) * parseFloat(assessment.maxTotalScore) * grading.result).toFixed(2))}/
            {parseFloat(((grading.weight / 100) * parseFloat(assessment.maxTotalScore)).toFixed(2))}
          </th>
          <td></td>
        </tr>
      );
    else
      return (
        <tr key={grading.id}>
          <th>{grading.gradingservice.name}</th>
          <th>{grading.result}</th>
          <th>{t("ABSOLUTE")} </th>
        </tr>
      );
  };
  function back() {
    navigate(-1); // go to previous page
  }

  function sortvaluation(a: any, b: any) {
    return b.length - a.length;
  }

  function getValuationItem(input: any, index: any) {
    var valuations = Object.keys(input);
    valuations.sort(sortvaluation).reverse();

    return (
      <div key={index}>
        <div>
          <ul>
            {valuations.map((valuation: any, index2: any) => {
              return (
                <div key={index2}>
                  {/*    <li>{valuation}</li> */}

                  {input[valuation].map((content: any, index3: any) => {
                    if (valuation === "POSITIVE")
                      return (
                        <li key={index3} className="no-bulletpoint">
                          {" "}
                          <FaArrowAltCircleUp className="has-text-success" /> &nbsp; {content[0]}
                          {content[1] !== 0 || content[2] !== 0 ? (
                            // eslint-disable-next-line no-useless-concat
                            " " + t("Position") + ":" + "(" + content[1] + "," + content[2] + ")"
                          ) : (
                            <div></div>
                          )}
                        </li>
                      );
                    else if (valuation === "NEGATIVE")
                      return (
                        <li key={index3} className="no-bulletpoint">
                          {" "}
                          <FaArrowAltCircleDown className="has-text-danger" /> &nbsp; {content[0]}
                          {content[1] !== 0 || content[2] !== 0 ? (
                            // eslint-disable-next-line no-useless-concat
                            " " + t("Position") + ":" + "(" + content[1] + "," + content[2] + ")"
                          ) : (
                            <div></div>
                          )}
                        </li>
                      );
                    else if (valuation === "NEUTRAL")
                      return (
                        <li key={index3} className="no-bulletpoint">
                          {" "}
                          <FaArrowAltCircleRight className="has-text-warning" /> &nbsp; {content[0]}
                          {content[1] !== 0 || content[2] !== 0 ? (
                            // eslint-disable-next-line no-useless-concat
                            " " + t("Position") + ":" + "(" + content[1] + "," + content[2] + ")"
                          ) : (
                            <div></div>
                          )}
                        </li>
                      );
                    else
                      return (
                        <li key={index3} className="no-bulletpoint">
                          {" "}
                          <FaCircle /> &nbsp; {content[0]}
                          {content[1] !== 0 || content[2] !== 0 ? (
                            // eslint-disable-next-line no-useless-concat
                            " " + t("Position") + ":" + "(" + content[1] + "," + content[2] + ")"
                          ) : (
                            <div></div>
                          )}
                        </li>
                      );
                  })}
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  const getTable = () => {
    return (
      <div>
        {answersLoaded ? (
          <div className="table-container">
            <table className="table is-striped is-narrow is-hoverable is-fullwidth">
              <tbody>
                {Object.keys(answers).map((ansid: any, index: any) => {
                  return getTableItem(ansid, index);
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };
  const handleCheckAnswer = () => {};
  const getTableItem = (ansid: any, index: any) => {
    let answersChecked = answersCheckedList[index] as any;
    return (
      <tr key={ansid}>
        <td>{answers[ansid].text}</td>
        <td>
          <label className="radio mr-5">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"true" + index}
              checked={
                index in answersCheckedList &&
                "selectionState" in answersCheckedList[index] &&
                answersChecked.selectionState === "true"
              }
              onChange={() => handleCheckAnswer()}
            />
            {t("true")}
          </label>
          <label className="radio mr-5">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"false" + index}
              checked={
                index in answersCheckedList &&
                "selectionState" in answersCheckedList[index] &&
                answersChecked.selectionState === "false"
              }
              onChange={() => handleCheckAnswer()}
            />
            {t("false")}
          </label>
          <label className="radio">
            <input
              className="mr-3"
              type="radio"
              name={"radioGroup" + index}
              id={"notSpecified" + index}
              checked={
                index in answersCheckedList &&
                "selectionState" in answersCheckedList[index] &&
                answersChecked.selectionState === "none"
              }
              onChange={() => handleCheckAnswer()}
            />
            {t("noAnswer")}
          </label>
        </td>
        <td className="is-hidden-mobile">
          {answers[ansid].status === "wrong" ? <FaTimes className="has-text-danger" /> : ""}
          {answers[ansid].status === "none" ? <FaQuestionCircle className="has-text-warning" /> : ""}
          {answers[ansid].status === "right" ? <FaCheck className="has-text-success" /> : ""}
        </td>
      </tr>
    );
  };

  const getCategoryItem = (category: any, index: any) => {
    return (
      <div key={index}>
        <h4 className="title is-6 mb-2 mt-3">{category}</h4>
        {getValuationItem(categoryMap[category], index)}
      </div>
    );
  };

  const getCategorycard = () => {
    return (
      <div>
        {Object.keys(categoryMap).map((category: any, index: any) => {
          return getCategoryItem(category, index);
        })}
      </div>
    );
  };

  let feedbackCard = (
    <div className="content">
      <div className="columns">
        <div className="column has-text-left">{getCategorycard()}</div>
      </div>
    </div>
  );

  let metatable = (
    <div>
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("requested")}</td>
              <td>{t("finished")}</td>
              {/* <td>{t("comment")}</td> */}
              <td>{t("gradingServices")}</td>
              <td>{t("percentage")}</td>
              <td>{t("Score")}</td>
            </tr>
          </thead>
          <tbody>
            <tr key={assessment.id}>
              {/*} TODO: Date in GMT */}
              <td> {new Date(assessment.createdAt).toLocaleDateString(i18n.language, dateOptions)}</td>
              <td> {new Date(assessment.updatedAt).toLocaleDateString(i18n.language, dateOptions)}</td>
              {/* 
                <td>
                  {assessment.comment ? <div>{assessment.comment}</div> : <div>{"no comment"}</div>}
                </td>
                */}
              <td>{Object.keys(assessment.gradings).length}</td>
              <td>{(assessment.totalscore / parseFloat(assessment.maxTotalScore)) * 100} %</td>
              <td>
                {Math.round(assessment.totalscore)} / {parseFloat(assessment.maxTotalScore)}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("gradingService")}</td>
              <td>{t("result")}</td>
              <td>{t("resulttype")}</td>
            </tr>
          </thead>
          <tbody>
            {assessment.gradings.map((grading: any) => {
              return getTableItemGrading(grading);
            })}
          </tbody>
        </table>
        {assessment.comment !== null && assessment.comment !== "" && assessment.comment !== " " ? (
          <div>
            <i>
              {" "}
              <h4 className="title is-6 mb-2 mt-3">{t("comment")}</h4>
            </i>
            <i>{assessment.comment}</i>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );

  return (
    <div className="section">
      <div className="column">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              {t("FeedbackForExercise")} »{submission_exercise.exercise ? submission_exercise.exercise.name : ""}«
            </p>
          </header>
          <div className="card-content">
            <div className="content" data-color-mode="light">
              <MDEditor.Markdown source={description} />
            </div>

            {modelId !== -1 && modelId !== undefined ? (
              <div className="mb-4">
                <ModelCardComponentMU modelId={modelId.toString()} />
              </div>
            ) : (
              <div> </div>
            )}

            <div className="columns ml-4">{question}</div>
            <div className="column">{getTable()}</div>
            <div className="columns">
              <div className="column has-text-left"></div>
            </div>
          </div>
        </div>

        <div className="columns mt-3">
          {feedbacks.length > 0 ? (
            <div className="column">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">{t("Feedback")}</p>
                </header>
                <div className="card-content">
                  <div className="content">
                    <div className="columns">
                      <div className="column has-text-weight-light">{feedbackCard}</div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column has-text-left"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t("Assessment")}</p>
              </header>
              <div className="card-content">
                <div className="content">
                  <div className="columns">
                    <div className="column has-text-weight-light">{metatable}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* check if feedForwardThreshold exists */}
         {feedForwardThreshold !== null ? (
          <div className="card mt-5">
            <header className="card-header">
              <p className="card-header-title">{t("feedForward")}</p>
            </header>
            <div className="card-content">
              {/* check if feedForwardThreshold exists */}
              {Math.round(assessment.totalscore) >= feedForwardThreshold ? (
                  /* totalscore >= feedForwardThreshold: show feedForwardPos */
                  <div className="columns">
                    <div className="column has-text-left">{feedForwardPos}</div>
                  </div>
                ) : (
                  /* totalscore < feedForwardThreshold: show feedForwardNeg */
                  <div className="columns">
                    <div className="column has-text-left">{feedForwardNeg}</div>
                  </div>
                )
              }
            </div>
          </div>
         ) : (
           ""
         )}
          </div>
        </div>
        <div>
          <button className="button is-danger" onClick={() => back()}>
            {t("back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCardMU;
