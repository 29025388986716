import { Exercise } from "./Exercise";

export type Assignment = {
  description: string;
  endDate: string;
  exercises: Exercise[];
  id: number;
  name: string;
  published: boolean;
  startDate: string;
  quickAccess: number;
};

export const emptyAssignment: Assignment = {
  description: "",
  endDate: "",
  exercises: [],
  id: -1,
  quickAccess: -1,
  name: "",
  published: false,
  startDate: "",
};
