import { ReactNode, useState } from "react";

import { ExerciseUnderstandingComponent } from "../components/exercise.understanding.component";
import { ModelUnderstandingContext } from "./ModelUnderstandingContext";

interface Props {
  children?: ReactNode;
}

export const ModelUnderstandingContextProvider = (props: Props) => {
  const [submissionId, setSubmissionId] = useState(-1);
  const [exerciseId, setExerciseId] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [assessmentMap, setAssessmentMap] = useState<any>({});
  const toggleModalProceed = () => {
    setModalVisible(!modalVisible);
    if (modalVisible) {
      delete assessmentMap[exerciseId];
    }
  };
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <ModelUnderstandingContext.Provider
      value={{
        setSubmissionId,
        setExerciseId,
        setAnswers,
        toggleModal,
        toggleModalProceed,
        setAssessmentMap,
      }}
    >
      {props.children}
      <div>
        {modalVisible ? (
          <ExerciseUnderstandingComponent
            submissionId={submissionId}
            exerciseId={exerciseId}
            answers={answers}
            modalVisible={modalVisible}
            toggleModal={toggleModal}
            toggleModalProceed={toggleModalProceed}
          />
        ) : (
          <div></div>
        )}
      </div>
    </ModelUnderstandingContext.Provider>
  );
};
