import { createContext, useContext } from "react";

interface IFeedbackModelEditorModalContext {
  submissionExerciseId: any;
  modelId: any;
  setSubmissionExerciseId: (id: any) => void;
  setModelId: (id: any) => void;
  toggleModalFeedback: () => void;
}

const defaultState: IFeedbackModelEditorModalContext = {
  submissionExerciseId: -1,
  modelId: -1,
  setSubmissionExerciseId: () => {
    console.warn("FeedbackModelEditorModalContext: not in scope.");
  },
  setModelId: () => {
    console.warn("FeedbackModelEditorModalContext: not in scope.");
  },
  toggleModalFeedback: () => {
    console.warn("FeedbackModelEditorModalContext: not in scope.");
  },
};

export const FeedbackModelEditorModalContext = createContext<IFeedbackModelEditorModalContext>(defaultState);
export const useFeedbackModelEditorModalContext = () => useContext(FeedbackModelEditorModalContext);
