import axiosInstance from "../axios";

class ModelDataService {
  getAll(exerciseId: number, modelType: string) {
    return axiosInstance.get(`/models`, {
      params: { exerciseId: exerciseId, type: modelType },
    });
  }

  create(data: any) {
    return axiosInstance.post("/models", data);
  }

  delete(id: any) {
    return axiosInstance.delete(`/models/${id}`);
  }

  updateModel(id: any, data: any) {
    return axiosInstance.put(`/models/${id}`, data);
  }
  getModelforSubex(id: any, type: any) {
    return axiosInstance.get(`/models`, {
      params: { exerciseId: id, type: type },
    });
  }
}

export default new ModelDataService();
