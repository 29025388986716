import axios from "axios";

console.log(window?._env_);
console.log(window);

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL:
    window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL + ":" + window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT + "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("Axios error:");
    console.log(error);
    if (error.response.data.path !== "/api/v1/auth/signin" && error.response.status === 401) {
      const url = window?._env_?.REACT_APP_LANDINGPAGE_URL + ":" + window?._env_?.REACT_APP_LANDINGPAGE_PORT;
      if (!url) return error;
      window.location.href = url;
    }
    return error;
  },
);

export default axiosInstance;
