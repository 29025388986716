import { hasSixDigits, isNumber } from "@kea-mod/common";
import { ChangeEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AssignmentDataService from "../../../axios/services/AssignmentDataService";
import { useNotificationContext } from "../../../context/NotificationContext";
import { useSubmissionContext } from "../../../context/SubmissionContext";
import { NotificationMessage } from "../../../types/Notification";

export const AccessCodeCard = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const submissionContext = useSubmissionContext();
  const notificationContext = useNotificationContext();

  const [accessCode, setAccessCode] = useState<string>("");
  const [accessCodeValid, setAccessCodeValid] = useState<boolean>(false);

  useEffect(() => {
    if (hasSixDigits(accessCode) && isNumber(accessCode)) {
      setAccessCodeValid(true);
    } else {
      setAccessCodeValid(false);
    }
  }, [accessCode, setAccessCode]);

  const getSuccessNotificationMessage = (id: string): NotificationMessage => {
    return {
      displayTime: 5000,
      message: t("assignmentAddSuccess"),
      type: "is-info",
      button: {
        className: "is-rounded is-info",
        label: t("assignmentOpen"),
        clb: () => {
          history(`/assignments/${id}`);
        },
      },
    };
  };

  const getWarningNotificationMessage = (): NotificationMessage => {
    return {
      displayTime: 2500,
      message: t("assignmentAddFailure"),
      type: "is-warning",
    };
  };

  const getAlreadyAddedNotificationMessage = (): NotificationMessage => {
    return {
      displayTime: 2500,
      message: t("assignmentAddAlready"),
      type: "is-warning",
    };
  };

  const submitQuickAccessCode = () => {
    AssignmentDataService.getQuickAccess(accessCode).then(
      (response: any) => {
        if ("response" in response) {
          if (response.response.data.statusCode === 400) {
            notificationContext.setNotification(getAlreadyAddedNotificationMessage());
            return;
          }
          if (response.response.data.statusCode === 404) {
            notificationContext.setNotification(getWarningNotificationMessage());
            return;
          }
        }

        if (response.data.success) {
          submissionContext.fetchOpenSubmissions();
          const submissionId = response.data.data.submission.id;

          notificationContext.setNotification(getSuccessNotificationMessage(submissionId));
          setAccessCode("");
          return;
        }
        notificationContext.setNotification(getWarningNotificationMessage());
      },
      (err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          setAccessCode("");
          notificationContext.setNotification(getAlreadyAddedNotificationMessage());
          return;
        }
      },
    );
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value);
  };

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{t("acesscodeCardHeader")}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div className="columns">
            <div className="column has-text-weight-light is-italic">{t("acesscodeCardBody")}</div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <input
              type="text"
              className="input"
              id="quick"
              required
              value={accessCode}
              onChange={handleInputChange}
              placeholder={t("acesscodeCardInputLabel")}
            />
          </div>
          <div className="column is-one-quarter">
            <button disabled={!accessCodeValid} className="button is-primary ml-0" onClick={submitQuickAccessCode}>
              {t("add")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
