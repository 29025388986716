import { createContext, useContext } from "react";

interface IExerciseModelEditorModalContext {
  taskId: any;
  modelId: any;
  assessmentMap: any;
  setTaskId: (id: any) => void;
  setModelId: (id: any) => void;
  toggleModal: () => void;
  setAssessmentMap: (map: any) => void;
}

const defaultState: IExerciseModelEditorModalContext = {
  taskId: -1,
  modelId: -1,
  assessmentMap: {},
  setTaskId: () => {
    console.warn("ExerciseModelEditorModalContext: not in scope.");
  },
  setAssessmentMap: () => {
    console.warn("ExerciseModelEditorModalContext: not in scope.");
  },
  setModelId: () => {
    console.warn("ExerciseModelEditorModalContext: not in scope.");
  },
  toggleModal: () => {
    console.warn("ExerciseModelEditorModalContext: not in scope.");
  },
};

export const ExerciseModelEditorModalContext = createContext<IExerciseModelEditorModalContext>(defaultState);
export const useExerciseModelEditorModalContext = () => useContext(ExerciseModelEditorModalContext);
